import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useLocation } from 'react-router-dom';

import * as sharedActions from '../../store/actions/Shared';
import * as loginActions from '../../store/actions/Login';
import * as usersActions from '../../store/actions/Users';
import * as accountsActions from '../../store/actions/Accounts';
import * as alertsActions from '../../store/actions/Alerts';
import * as themeActions from "../../store/actions/Theme";

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import { Animation, Drawer, Alert, Loader, Whisper, Tooltip } from 'rsuite';

import { SETFile } from '../../services/WebServices';

import Input from './utilities/Input';
import Numeric from './utilities/Numeric';
import Picker from './utilities/Picker';
import Select from './utilities/Select';
import MultipleSelect from './utilities/MultipleSelect';
import UserCreate from './users/UserCreate';
import UserList from './users/Users';

import useTour from '../onboarding/hook/useTour';
import { stepsUser } from '../onboarding/steps/UserSteps';


const isValidateEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const isValidUser = user => {

    if (user.name === null || user.name === "") {
        return false;
    }

    if (user.lastname === null || user.lastname === "") {
        return false;
    }

    if (!isValidateEmail(user.email)) {
        return false;
    }

    if (user.phone === null || user.phone === "") {
        return false;
    }

    if (user.address === null || user.address === "") {
        return false;
    }

    return true;

}

const showError = (user, setFocus, setError, setShowMainInfo, language) => {

    if (user.name === null || user.name === "") {
        setFocus(1);
        setError(language.alert_user_popup_name);
        setShowMainInfo(true);
        return;
    }

    if (user.lastname === null || user.lastname === "") {
        setFocus(2);
        setError(language.alert_user_popup_lastname);
        setShowMainInfo(true);
        return;
    }

    if (!isValidateEmail(user.email)) {
        setFocus(3);
        setError(language.alert_user_popup_email);
        setShowMainInfo(true);
        return;
    }

    if (user.phone === null || user.phone === "") {
        setFocus(4);
        setError(language.alert_user_popup_phone);
        setShowMainInfo(true);
        return;
    }

    if (user.address === null || user.address === "") {
        setFocus(5);
        setError(language.alert_user_popup_address);
        setShowMainInfo(true);
        return;
    }

    return setFocus(0);

}

const Account = (props) => {

    const location = useLocation();

    const setUserImage = (user, setIsLoading, e) => {

        const file = e.target.files[0];

        const data = new FormData();

        data.append("file", file);
        data.append("id", user.idUser.toString());
        data.append("folder", "profile-photos");

        setIsLoading(true);

        SETFile("users/users/", "PUT", data)
            .then(_result => {
                props.getUserImageAsync();
            })
            .catch(() => {
                Alert.error(props.language.alert_failed_change_image);
            })
            .finally(() => setIsLoading(false));

    }

    let languages = [
        {
            value: 1,
            label: props.language.user_select_option_language_es
        },
        {
            value: 2,
            label: props.language.user_select_option_language_en
        }
    ];

    let property_types = [
        {
            value: 1,
            label: props.language.user_select_option_type_apartment
        },
        {
            value: 2,
            label: props.language.user_select_option_type_house
        },
        {
            value: 3,
            label: props.language.user_select_option_type_country_house
        },
        {
            value: 4,
            label: props.language.user_select_option_type_farm
        }
    ];

    let _user = props.user;
    let userJobs = props.userJobs;
    let language = props.language;
    let showCreateUserModal = props.showCreateUserModal;
    let showDrawerAlerts = props.showDrawerAlerts

    const setProfileProgress = props.setProfileProgress;
    const loadUserJobsAsync = props.loadUserJobsAsync;
    const getUserApiTokenAsync = props.getUserApiTokenAsync;

    const [user, setUserData] = useState(_user);
    const [userChanges, setUserChanges] = useState({});
    const [isEnable, setEnable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [personalData, setShowPersonalData] = useState(false);
    const [mainInfo, setShowMainInfo] = useState(false);
    const [healthInfo, setShowHealthInfo] = useState(false);
    const [homeInfo, setShowHomeInfo] = useState(false);
    const [vehicleInfo, setShowVehicleInfo] = useState(false);
    const [tokenApi, setTokenApi] = useState(false);
    const [focus, setFocus] = useState(0);
    const [error, setError] = useState("");

    const tourUser = useTour({ steps: stepsUser(props.language), localStorageKey: "user-tutorial", isOpenDrawer: showCreateUserModal, runTour: props.newUser, setRunTour: props.setStartOnboarding, key: 'new_user' });

    const { theme } = props;

    const getProgressStyle = () => {
        
        return {
            path: { stroke: theme === 'dark' ? '#9ecc14'  : '#7da60c' },
            trail: { stroke: theme === 'dark' ? '#262626'  : '#fafafa' }
        }
    }

    useEffect(() => {
        setUserData({..._user, roleIds: _user.jobs?.map(j => j.idJob)});
    }, [_user]);

    useEffect(() => {
        if (!userJobs.length)
            loadUserJobsAsync();
    }, [userJobs, loadUserJobsAsync]);

    useEffect(() => {
        setEnable(isValidUser(user));
        setProfileProgress(Object.values(user).filter(x => x !== undefined && x !== null && x !== '').length / Object.keys(user).length);
    }, [setEnable, setProfileProgress, user]);

    useEffect(() => {
        if (focus !== 0) { showError(user, setFocus, setError, setShowMainInfo, language) }
    }, [user, language, focus]);

    useEffect(() => { getUserApiTokenAsync() }, [getUserApiTokenAsync]);

    return (
        <Fragment>
            {tourUser}
            <Drawer
                show={props.showAccount}
                size={'xs'}
                placement={'right'}
                backdropClassName={`pe-pointer ${theme}`}
                onHide={() => props.setShowAccount(false)}>
                <div id='profile' className={`column-start align-items-center py-3 vh-100 w-100 overflow-y-scroll surface-bg-surface ${theme}`}>
                    <div style={{ height: 100, width: 100 }}>
                        <CircularProgressbarWithChildren value={props.progress * 100} strokeWidth={6} styles={getProgressStyle()}>
                            <div className="container-image row-center align-items-center" style={{ padding: 12 }}>
                                {isLoading ? <div className="bg-gray-400 position-absolute row-center align-items-center w-75 h-75 zindex-1 rounded-circle"><Loader inverse /></div> : null}
                                <label htmlFor="user-img" className="row-center align-items-center rounded-circle bg-gray-400 h-100 w-100 pe-pointer">
                                    {!user.image ?
                                        <i className="image-user fa-solid fa-user txt-gray-500 txt-18"></i> :
                                        <img id="caption" className="image-user w-100 h-100 overflow-hidden rounded-circle" src={`${process.env.REACT_APP_IMAGES_URL}/profile-photos/${user.idUser}/150x150/${user.image}`} alt="user-profile" />
                                    }
                                    <div className="middle">
                                        <i className="fa-solid fa-edit txt-white txt-16"></i>
                                    </div>
                                </label>
                                <input type="file" id="user-img" name="user-img" accept="image/*" className="d-none" onChange={e => setUserImage(user, setIsLoading, e)}></input>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className="column-start align-items-center my-3">
                        <div className={`mb-2 heading-h6-semibold content-default-primary ${theme}`}>{user.name} {user.lastname}</div>
                    </div>
                    <div className="w-100 ps-4 pe-2">
                        <div id='profile_menu' className={`column-start align-items-center w-100 p-4 rounded-16 surface-bg-on-surface border-fill-medium ${theme}`}>
                            <div id='section_personal_data' className="row-between align-items-center w-100 pe-pointer" onClick={() => setShowPersonalData(!personalData)}>
                                <div className="row-start align-items-center">
                                    <i className={`fa-regular fa-user txt-10 content-default-secondary ${theme}`}></i>
                                    <div className={`label-small-medium ms-3 pe-pointer content-default-primary ${theme}`}>{props.language.user_title_section_personal_data}</div>
                                </div>
                                <i className={`fa-regular content-default-secondary ${theme} txt-10 pe-pointer ${!personalData ? 'account-detail-open fa-circle-chevron-right' : 'account-detail-close fa-circle-chevron-down'}`}></i>
                            </div>
                            <Animation.Collapse in={personalData} dimension={'height'}>
                                {/* <div className="column-start align-items-center w-100">
                                    <div className="w-100 py-4">
                                        <div className="row-between align-items-center pe-pointer w-100 p-2" onClick={() => setShowMainInfo(!mainInfo)}>
                                            <div className={`label-small-medium pe-pointer content-default-primary ${theme}`}>{props.language.user_title_section_your_info}</div>
                                            <i className={`fa-regular txt-10 pe-pointer content-default-primary ${theme} ${!mainInfo ? 'fa-plus' : 'fa-minus'}`}></i>
                                        </div>
                                        <Animation.Collapse in={mainInfo} dimension={'height'}>
                                            <div className="column-start align-items-center w-100">
                                                <div className="w-100 py-4">
                                                    <div className="w-100 my-3">
                                                        <Input disabled label={props.language.user_input_label_id_document} type={'text'} value={user.idDocument ?? ""}/>
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input
                                                            label={props.language.user_input_label_names}
                                                            type={'text'}
                                                            value={user.name ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, name: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, name: target.value }
                                                                })
                                                            }}
                                                            error={focus === 1 ? error : ""}
                                                        />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input
                                                            label={props.language.user_input_label_lastname}
                                                            type={'text'}
                                                            value={user.lastname ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, lastname: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, lastname: target.value }
                                                                })
                                                            }}
                                                            error={focus === 2 ? error : ""}
                                                        />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input label={props.language.user_input_label_email} type={'email'} value={user.email ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, email: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, email: target.value }
                                                                })
                                                            }}
                                                            error={focus === 3 ? error : ""}
                                                        />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input label={props.language.user_input_label_phone} type={'tel'} value={user.phone ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, phone: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, phone: target.value }
                                                                })
                                                            }}
                                                            error={focus === 4 ? error : ""}
                                                        />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input label={props.language.user_input_label_optional_phone} type={'tel'} value={user.phone2 ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, phone2: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, phone2: target.value }
                                                                })
                                                            }} />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input label={props.language.user_input_label_address} type={'text'} value={user.address ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, address: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, address: target.value }
                                                                })
                                                            }}
                                                            error={focus === 5 ? error : ""}
                                                        />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input label={props.language.user_input_label_optional_address} type={'text'} value={user.address2 ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, address2: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, address2: target.value }
                                                                })
                                                            }} />
                                                    </div>
                                                    <div className="col-12">
                                                        <MultipleSelect
                                                            keyName='idJob'
                                                            labelName='valueText'
                                                            language={props.language}
                                                            label={props.language.user_selected_label_job_area}
                                                            width={230}
                                                            options={userJobs}
                                                            selectedData={user.roleIds || []}
                                                            onChange={(value) => {
                                                                setUserData({ ...user, roleIds: value });
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, roleIds: value }
                                                                });
                                                            }}
                                                            error={focus === 6 ? error : ""}
                                                            value={user?.roleIds?.length ? `${user?.roleIds.length}` : props.language.user_select_label_job_area}
                                                        />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Select label={props.language.user_select_label_language} value={user.idLanguage ?? 0} options={languages}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, idLanguage: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, idLanguage: target.value }
                                                                })
                                                            }} />
                                                    </div>
                                                    <div className="w-100 my-2">
                                                        <Select
                                                            label={props.language.user_select_label_job_area}
                                                            keyName={userJobs.length && 'idJob'}
                                                            language={props.language}
                                                            labelName='valueText'
                                                            background={'gray-800'}
                                                            value={user.job?.idJob ?? 0}
                                                            options={userJobs}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, job: { idJob: parseInt(target.value) } })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, idJob: parseInt(target.value) }
                                                                })
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Animation.Collapse>
                                        {/* {
                                            user.capabilities?.show_user_profile_extra_info ?
                                                <Fragment>
                                                    <div className="row-between align-items-center w-100 p-2" onClick={() => setShowHealthInfo(!healthInfo)}>
                                                        <div className="txt-phrase txt-semibold txt-white txt-8">{props.language.user_title_section_health}</div>
                                                        <i className={`fa-regular fa-chevron-right txt-white txt-12 pe-pointer ${!healthInfo ? 'account-detail-open' : 'account-detail-close'}`}></i>
                                                    </div>
                                                    <Animation.Collapse in={healthInfo} dimension={'height'}>
                                                        <div className="column-start align-items-center w-100">
                                                            <div className="w-100 py-4">
                                                                <div className="w-100 my-2">
                                                                    <Input label={props.language.user_input_label_height} type={'number'} value={user.height ?? ""}
                                                                        onChange={({ target }) => {
                                                                            setUserData({ ...user, height: target.value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, height: target.value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                                <div className="w-100 my-2">
                                                                    <Input label={props.language.user_input_label_weight} type={'number'} value={user.weight ?? ""}
                                                                        onChange={({ target }) => {
                                                                            setUserData({ ...user, weight: target.value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, weight: target.value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                                <div className="w-100 my-2">
                                                                    <Input label={props.language.user_input_label_eps} type={'text'} value={user.eps ?? ""}
                                                                        onChange={({ target }) => {
                                                                            setUserData({ ...user, eps: target.value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, eps: target.value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                                <div className="w-100 my-2">
                                                                    <Picker label={props.language.user_select_label_insurance} value={user.insurance}
                                                                        onChange={(value) => {
                                                                            setUserData({ ...user, insurance: value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, insurance: value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Animation.Collapse>
                                                    <div className="row-between align-items-center w-100 p-2" onClick={() => setShowHomeInfo(!homeInfo)}>
                                                        <div className="txt-phrase txt-semibold txt-white txt-8">{props.language.user_title_section_home}</div>
                                                        <i className={`fa-regular fa-chevron-right txt-white txt-12 pe-pointer ${!homeInfo ? 'account-detail-open' : 'account-detail-close'}`}></i>
                                                    </div>
                                                    <Animation.Collapse in={homeInfo} dimension={'height'}>
                                                        <div className="column-start align-items-center w-100">
                                                            <div className="w-100 py-4">
                                                                <div className="w-100 my-2">
                                                                    <Select label={props.language.user_input_label_type_property} background={'gray-800'} value={user.propertyType ?? 1} options={property_types}
                                                                        onChange={({ target }) => {
                                                                            setUserData({ ...user, propertyType: target.value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, propertyType: target.value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                                <div className="w-100 my-2">
                                                                    <Input label={props.language.user_input_label_house_area} type={'text'} value={user.houseArea ?? ""}
                                                                        onChange={({ target }) => {
                                                                            setUserData({ ...user, houseArea: target.value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, houseArea: target.value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                                <div className="w-100 my-2">
                                                                    <Numeric label={props.language.user_input_label_house_people} value={user.housePeople ?? 1}
                                                                        onChange={(value) => {
                                                                            setUserData({ ...user, housePeople: value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, housePeople: value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                                <div className="w-100 my-2">
                                                                    <Numeric label={props.language.user_input_label_house_levels} value={user.floors ?? 1}
                                                                        onChange={(value) => {
                                                                            setUserData({ ...user, floors: value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, floors: value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                                <div className="w-100 my-2">
                                                                    <Picker label={props.language.user_select_label_own_home} value={user.ownHome}
                                                                        onChange={(value) => {
                                                                            setUserData({ ...user, ownHome: value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, ownHome: value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                                <div className="w-100 my-2">
                                                                    <Picker label={props.language.user_picker_label_solar_system} value={user.solarSystem}
                                                                        onChange={(value) => {
                                                                            setUserData({ ...user, solarSystem: value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, solarSystem: value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Animation.Collapse>
                                                    <div className="row-between align-items-center w-100 p-2" onClick={() => setShowVehicleInfo(!vehicleInfo)}>
                                                        <div className="txt-phrase txt-semibold txt-white txt-8">{props.language.user_title_section_vehicle}</div>
                                                        <i className={`fa-regular fa-chevron-right txt-white txt-12 pe-pointer ${!vehicleInfo ? 'account-detail-open' : 'account-detail-close'}`}></i>
                                                    </div>
                                                    <Animation.Collapse in={vehicleInfo} dimension={'height'}>
                                                        <div className="column-start align-items-center w-100">
                                                            <div className="w-100 py-4">
                                                                <div className="w-100 my-2">
                                                                    <Picker label={props.language.user_picker_label_own_vehicle} value={user.ownVehicle}
                                                                        onChange={(value) => {
                                                                            setUserData({ ...user, ownVehicle: value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, ownVehicle: value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                                <div className="w-100 my-2">
                                                                    <Picker label={props.language.user_picker_label_electric_vehicle} value={user.ev}
                                                                        onChange={(value) => {
                                                                            setUserData({ ...user, ev: value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, ev: value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                                <div className="w-100 my-2">
                                                                    <Picker label={props.language.user_picker_label_armored_vehicle} value={user.armoredVehicle}
                                                                        onChange={(value) => {
                                                                            setUserData({ ...user, armoredVehicle: value })
                                                                            setUserChanges(prevState => {
                                                                                return { ...prevState, armoredVehicle: value }
                                                                            })
                                                                        }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Animation.Collapse>
                                                </Fragment> : null
                                        } */}
                                        
                                        {/* <button className="row-center align-items-center txt-phrase txt-semibold txt-8 bg-green-500 txt-neutral-800 mt-4 py-2 rounded-8 w-100 border-0"
                                            style={{ height: 45 }}
                                            onClick={() => {
                                                if (!props.savingInfoUser) {
                                                    if (isEnable) { props.updateInfoUserAsync(userChanges) }
                                                    else {
                                                        showError(user, setFocus, setError, setShowMainInfo, language);
                                                    }
                                                }
                                            }}>{!props.savingInfoUser ?
                                                <Fragment>
                                                    {props.language.user_save_button}
                                                    <i className="fa-solid fa-save txt-12 ms-3"></i>
                                                </Fragment> :
                                                <Loader size='sm' content="Guardando..." />
                                            }
                                        </button> */}
                                    {/* </div> */}
                                {/* </div> */} 
                                <div className="column-start align-items-center w-100">
                                            <div className="column-start align-items-center w-100">
                                                <div className="w-100 py-4">
                                                    <div className="w-100 my-3">
                                                        <Input disabled label={props.language.user_input_label_id_document} type={'text'} value={user.idDocument ?? ""}/>
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input
                                                            label={props.language.user_input_label_names}
                                                            type={'text'}
                                                            value={user.name ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, name: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, name: target.value }
                                                                })
                                                            }}
                                                            error={focus === 1 ? error : ""}
                                                        />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input
                                                            label={props.language.user_input_label_lastname}
                                                            type={'text'}
                                                            value={user.lastname ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, lastname: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, lastname: target.value }
                                                                })
                                                            }}
                                                            error={focus === 2 ? error : ""}
                                                        />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input label={props.language.user_input_label_email} type={'email'} value={user.email ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, email: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, email: target.value }
                                                                })
                                                            }}
                                                            error={focus === 3 ? error : ""}
                                                        />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input label={props.language.user_input_label_phone} type={'tel'} value={user.phone ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, phone: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, phone: target.value }
                                                                })
                                                            }}
                                                            error={focus === 4 ? error : ""}
                                                        />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Input label={props.language.user_input_label_address} type={'text'} value={user.address ?? ""}
                                                            onChange={({ target }) => {
                                                                setUserData({ ...user, address: target.value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, address: target.value }
                                                                })
                                                            }}
                                                            error={focus === 5 ? error : ""}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <MultipleSelect
                                                            keyName='idJob'
                                                            labelName='valueText'
                                                            language={props.language}
                                                            label={props.language.user_selected_label_job_area}
                                                            width={278}
                                                            options={userJobs}
                                                            selectedData={user.roleIds || []}
                                                            onChange={(value) => {
                                                                setUserData({ ...user, roleIds: value });
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, roleIds: value }
                                                                });
                                                            }}
                                                            error={focus === 6 ? error : ""}
                                                            value={user?.roleIds?.length ? `${user?.roleIds.length}` : props.language.user_select_label_job_area}
                                                        />
                                                    </div>
                                                    <div className="w-100 my-3">
                                                        <Select label={props.language.user_select_label_language} value={user.idLanguage ?? 0} options={languages}
                                                            onChange={(value) => {
                                                                setUserData({ ...user, idLanguage: value })
                                                                setUserChanges(prevState => {
                                                                    return { ...prevState, idLanguage: value }
                                                                })
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                           <button className={`row-center align-items-center py-2 rounded-30 w-100 button-fill-brand button-content-equal label-small-regular ${theme}`}
                                            onClick={() => {
                                                if (!props.savingInfoUser) {
                                                    if (isEnable) { props.updateInfoUserAsync(userChanges) }
                                                    else {
                                                        showError(user, setFocus, setError, setShowMainInfo, language);
                                                    }
                                                }
                                            }}>{!props.savingInfoUser ?
                                                <Fragment>
                                                    <i className="fa-regular fa-floppy-disk txt-10 me-2"></i>
                                                    {props.language.user_save_button}
                                                </Fragment> :
                                                <Loader size='sm' content="Guardando..." />
                                            }
                                            </button>
                                </div>
                            </Animation.Collapse>
                            {location.pathname !== '/home' &&
                                <div className="row-between align-items-center w-100 mt-4 pe-pointer" onClick={() => { props.setShowDrawerAlerts(true); }}>
                                    <div className="row-start align-items-center">
                                        <i className={`fa-regular fa-sensor-on txt-10 content-default-secondary ${theme}`}></i>
                                        <div className={`label-small-medium ms-3 pe-pointer content-default-primary ${theme}`}>{props.language.user_title_section_alerts}</div>
                                    </div>
                                    <i className={`fa-regular content-default-secondary ${theme} txt-10 pe-pointer ${!showDrawerAlerts ? 'fa-circle-chevron-right' : 'fa-circle-chevron-down'}`}></i>
                                </div>
                            }
                            <div id='section_user' className="row-between align-items-center w-100 mt-4 pe-pointer" onClick={() => { props.setShowCreateUserModal(true); props.setUserAccessEdit(null); }}>
                                <div className="row-start align-items-center">
                                    <i className={`fa-regular fa-user-plus txt-10 content-default-secondary ${theme}`}></i>
                                    <div className={`label-small-medium ms-3 pe-pointer content-default-primary ${theme}`}>{props.language.user_title_section_create_users}</div>
                                </div>
                                <i className={`fa-regular txt-10 pe-pointer content-default-secondary ${theme} ${!showCreateUserModal ? 'fa-circle-chevron-right' : 'fa-circle-chevron-down'}`}></i>
                            </div>
                        </div>
                        <div className={`row-between align-items-center w-100 mt-3 pe-pointer p-3 rounded-16 my-3 surface-bg-on-surface border-fill-medium ${theme}`} onClick={() => setTokenApi(!tokenApi)}>
                            <div className="row-start align-items-center">
                                <i className={`fa-regular fa-key txt-10 content-default-secondary ${theme}`}></i>
                                <div className={`label-small-medium ms-3 pe-pointer content-default-primary ${theme}`}>{props.language.user_title_section_api_token}</div>
                            </div>
                            <i className={`fa-regular txt-10 pe-pointer content-default-secondary ${theme} ${!tokenApi ? 'account-detail-open fa-circle-chevron-right' : 'account-detail-close fa-circle-chevron-down'}`}></i>
                        </div>
                        <Animation.Collapse in={tokenApi} dimension={'height'}>
                            <div className={`column-center align-items-center surface-bg-on-surface border-fill-medium rounded-16 w-100 ${theme}`} >
                                <div className="column-between w-100 h-100 p-3">
                                    <p className={`txt-phrase txt-semibold txt-10 content-color-green text-center ${theme}`}>{props.language.user_title_section_api_token}</p>
                                    <div className="input-group my-3">
                                        <input value={props.userTokenApi ?? props.language.api_user_token_tooltip_empty} type="text" disabled className={`form-control surface-bg-surface border-0 content-default-primary ${theme}`} />
                                        <div className="input-group-append">
                                            <Whisper
                                                delay={200}
                                                placement="top"
                                                trigger="click"
                                                speaker={<Tooltip>{props.language.neugets_tooltip_copy_code}</Tooltip>}>
                                                <button className={`btn surface-bg-surface ${theme}`} type="button" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => navigator.clipboard.writeText(props.userTokenApi ?? "")}>
                                                    <i className={`fa-regular fa-copy content-default-primary txt-12 ${theme}`}></i>
                                                </button>
                                            </Whisper>
                                        </div>
                                    </div>
                                    <div className='w-100 row-center gap-2 align-items-center'>
                                        <button className={`row-center align-items-center w-100 p-2 rounded-30 button-fill-brand ${theme}`} onClick={() => { props.generateUserApiTokenAsync(); getUserApiTokenAsync(); }}>
                                            <i className={`fa-solid fa-key txt-8 me-2 button-content-equal ${theme}`}></i>
                                            <p className={`button-content-equal label-small-regular pe-pointer ${theme}`}>{props.language.user_button_generate_token}</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Animation.Collapse>
                        <div className={`column-start mt-3 p-3 rounded-16 surface-bg-on-surface border-fill-medium ${theme}`}>
                            <div id='section_onboarding' className="row-between align-items-center w-100 pe-pointer" onClick={() => props.setShowDrawerOnboarding(true)}>
                                <div className="row-start align-items-center">
                                    <i className={`fa-regular fa-message-lines txt-10 content-default-secondary ${theme}`}></i>
                                    <div className={`label-small-medium ms-3 pe-pointer content-default-primary ${theme}`}>{props.language.onboarding_drawer_title}</div>
                                </div>
                                <i className={`far fa-circle-chevron-right txt-10 pe-pointer content-default-secondary ${theme}`}></i>
                            </div>
                        </div>
                        <div className={`column-start align-items-center w-100 p-3 rounded-16 my-3 surface-bg-on-surface border-fill-medium ${theme}`}>
                            <div className="row-between align-items-center w-100 pe-pointer" onClick={() => window.open("https://www.neu.com.co/privacy", "_blank")}>
                                <div className="row-start align-items-center">
                                    <i className={`fa-regular fa-shield txt-10 content-default-secondary ${theme}`}></i>
                                    <div className={`label-small-medium ms-3 pe-pointer content-default-primary ${theme}`}>{props.language.user_title_section_privacy_info}</div>
                                </div>
                                <i className={`fa-regular fa-circle-chevron-right txt-10 pe-pointer content-default-secondary ${theme}`}></i>
                            </div>
                            <div className="row-between align-items-center w-100 mt-4 pe-pointer" onClick={() => window.open("https://neu-public.s3.sa-east-1.amazonaws.com/documents/Contrato_de_Condiciones_Uniformes.pdf", "_blank")}>
                                <div className="row-start align-items-center">
                                    <i className={`fa-regular fa-asterisk txt-10 content-default-secondary ${theme}`}></i>
                                    <div className={`label-small-medium ms-3 pe-pointer content-default-primary ${theme}`}>{props.language.user_title_section_terms_conditions}</div>
                                </div>
                                <i className={`fa-regular fa-circle-chevron-right txt-10 pe-pointer content-default-secondary ${theme}`}></i>
                            </div>
                        </div>
                        <div className={`column-start align-items-center w-100 p-2 rounded-30 my-3 pe-pointer button-fill-error ${theme}`} onClick={() => {
                            props.logout();
                            props.setStep(1);
                        }}>
                            <div className={`label-small-regular pe-pointer txt-white ${theme}`}>{props.language.user_button_logout}</div>
                        </div>
                    </div>
                    <UserCreate />
                    <UserList />
                </div>
            </Drawer>
        </Fragment>);
}

const mapStateToProps = (state) => ({
    user: state.users.user,
    progress: state.users.profileProgress,
    savingInfoUser: state.users.savingInfoUser,
    totalAccounts: state.accounts.totalAccounts,
    account: state.accounts.account,
    userJobs: state.users.userJobs,
    language: state.shared.language,
    userTokenApi: state.users.userTokenApi,
    showCreateUserModal: state.users.showCreateUserModal,
    newUser: state.shared.startOnboarding.new_user,
    theme: state.theme.theme,
    showDrawerAlerts: state.alerts.showDrawerAlerts
})

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(sharedActions.logout()),
    setAccount: (payload) => dispatch(accountsActions.setAccount(payload)),
    setStep: (payload) => dispatch(loginActions.setStep(payload)),
    setProfileProgress: (payload) => dispatch(usersActions.setProfileProgress(payload)),
    setShowCreateUserModal: (payload) => dispatch(usersActions.setShowCreateUserModal(payload)),
    setUserAccessEdit: (payload) => dispatch(usersActions.setUserAccessEdit(payload)),
    setShowUsersListModal: (payload) => dispatch(usersActions.setShowUsersListModal(payload)),
    updateInfoUserAsync: (payload) => dispatch(usersActions.updateInfoUserAsync(payload)),
    loadUserJobsAsync: () => dispatch(usersActions.loadUserJobsAsync()),
    getUserImageAsync: () => dispatch(usersActions.getUserImageAsync()),
    generateUserApiTokenAsync: () => dispatch(usersActions.generateUserApiTokenAsync()),
    getUserApiTokenAsync: () => dispatch(usersActions.getUserApiTokenAsync()),
    setStartOnboarding: (payload) => dispatch(sharedActions.setStartOnboarding(payload)),
    setShowDrawerAlerts: (payload) => dispatch(alertsActions.setShowDrawerAlerts(payload)),
    setShowDrawerOnboarding: (payload) => dispatch(sharedActions.setShowDrawerOnboarding(payload)),
    setTheme: (theme) => dispatch(themeActions.setTheme(theme)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)


