import React from "react"

export const stepsGraphExportData = (language, theme) => {
  return ([
    {      
      content: (
        <div className={`column-center align-items-center gap-2 w-100 rounded-8 mt-3 surface-bg-fill ${theme}`} style={{ height: 150 }}>
          <div style={{ height: "35%" }}>
            <div className={`row-center align-items-center alisurface-bg-on-surface border-fill-medium rounded-pill overflow-hidden mt-2 ${theme}`} style={{width: 60, height: 60}}>
              <i className={`fa-light fa-circle-arrow-down txt-16 content-default-primary ${theme}`} />              
            </div>
          </div>          
        </div>
      ),
      title: language.onboarding_graph_export_data_title_step1,
      description: language.onboarding_graph_export_data_description_step1,
      placement: "auto",            
      target: "#export_data_picker",
      disableBeacon: true,
      steps: 1     
    }    
  ])
}
