import React from "react"

import LogoLight from '../../../imgs/logo-enrg-light.svg'
import LogoDark from '../../../imgs/logo-enrg-dark.svg'
import AnimatedComponent from "../utils/AnimatedComponent"
import ThemedImage from "../utils/ThemedImage"

import { themes } from '../../../utils/enums';

const services = [
  { title: "Home", icon: "energy" },
  { title: "Plus", icon: "plus" },
  { title: "Genius", icon: "genius" },
  { title: "Efficiency", icon: "efficiency" },
]

export const stepsDashboard = (language, theme) => {
  return ([
    {
      content: (
        <div className={`row-center align-items-center w-100 rounded-8 mt-3 surface-bg-fill ${theme}`} style={{ height: 140 }}>
          <img src={theme === themes.DARK ? LogoDark : LogoLight} alt="logo-neu-energy" style={{ width: 80 }} />         
        </div>
      ),
      title: language.onboarding_dashboard_title_step1,
      description: language.onboarding_dashboard_description_step1,
      placement: "center",
      target: "#navigate_dashboard",
      disableBeacon: true,
      steps: 7,
    },    
    {
      content: (
        <div className={`row-center align-items-center w-100 rounded-8 mt-3 surface-bg-fill ${theme}`} style={{ height: 130 }}>
          <div className={`w-75 rounded-16 row-center align-items-center surface-bg-on-surface ${theme}`} style={{ height: 60 }}>
            <AnimatedComponent data={services} type={0} theme={theme} />
          </div>
        </div>
      ),
      title: language.onboarding_dashboard_title_step2,
      description: language.onboarding_dashboard_description_step2,
      placement: "right",
      target: "#navigate_modules",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      steps: 7,
    },   
    {
      content:
        <div className='row-center align-items-center w-100 mt-3' style={{ height: 124, width: 300 }}>
            <ThemedImage imageName={'step1-profile.png'}/>
        </div>,
      title: language.onboarding_dashboard_title_step3,
      description: language.onboarding_dashboard_description_step3,
      placement: "right",
      target: "#navigate_modules",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      steps: 7,
    }, 
    {
      content: (
        <div className={`column-center align-items-center gap-2 w-100 rounded-8 mt-3 surface-bg-fill ${theme}`} style={{ height: 150 }}>
          <div style={{ height: "35%" }}>
            <div className={`row-center align-items-center alisurface-bg-on-surface border-fill-medium rounded-pill overflow-hidden mt-2 ${theme}`} style={{width: 60, height: 60}}>
              <i className={`fa-light fa-headset txt-16 content-default-primary ${theme}`} />              
            </div>
          </div>          
        </div>
      ),
      title: language.onboarding_dashboard_title_step4,
      description: language.onboarding_dashboard_description_step4,
      placement: "auto",      
      target: "#navigate_dashboard",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      steps: 7,
    },    
    {
      content: (
        <div className={`column-center align-items-center gap-2 w-100 rounded-8 mt-3 surface-bg-fill ${theme}`} style={{ height: 150 }}>
          <div style={{ height: "35%" }}>
            <div className={`row-center align-items-center alisurface-bg-on-surface border-fill-medium rounded-pill overflow-hidden mt-2 ${theme}`} style={{width: 60, height: 60}}>
              <i className={`fa-light fa-sun-bright txt-16 content-default-primary ${theme}`} />              
            </div>
          </div>          
        </div>
      ),
      title: language.onboarding_dashboard_title_step5,
      description: language.onboarding_dashboard_description_step5,
      placement: "auto",      
      target: "#navigate_dashboard",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      steps: 7,
    },   
  ])
}
