import React, { useEffect, useState } from "react";

import Popover from "@idui/react-popover";

import { track } from "@amplitude/analytics-browser";

const SubMenuGraphs = ({
  graphData,
  setControlRemoteClick,
  activeGraphs,
  setGraphActive,
  selectedGraphs,
  showSubmenuGraphs,
  setShowSubmenuGraphs,
  isDisabled,
  language,
  theme,
}) => {
  const [show, setShow] = useState(showSubmenuGraphs === graphData[0].group);
  // These graphs won't be activated until the user press the button
  const [graphActiveTemporal, setGraphActiveTemporal] = useState([
    ...selectedGraphs,
  ]);

  useEffect(() => {
    setGraphActiveTemporal(selectedGraphs);
  }, [selectedGraphs]);

  const areArrayEquals = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  };

  return (
    <Popover
      lazy
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={"click"}
      isOpen={show || showSubmenuGraphs === graphData[0].group}
      placement="right"
      guessBetterPosition
      className={`p-0 rounded-8 surface-bg-surface submenu-graphs ${theme}`}
      withArrow={true}
      arrowColor={theme === 'dark' ? '#141414' : '#ffff'}
      arrowSize={20}
      width="280px"
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      spaceBetweenPopoverAndTarget={40}
      onChangeOpen={(state) => {
        if (!isDisabled) {
          setShow(state);
          setControlRemoteClick(state);
          setShowSubmenuGraphs("");
        }
      }}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      content={
        <div className="p-3 border-none-scroll">
          <div className="column-start graphs-picker-container mb-3">
            <div className="column-start gap-2">
              {graphData.map((_graph) => {
                const isSelected =
                  graphActiveTemporal.findIndex((i) => i === _graph.value) >= 0;

                return (
                  <div
                    key={_graph.id_graph}
                    className={`row-between align-items-center pe-pointer p-2 rounded-8 gap-2 ${theme} ${
                      isSelected ? "surface-bg-on-surface" : "surface-bg-surface"
                    }`}
                    onClick={() => {
                      if (isSelected && graphActiveTemporal.length > 0) {
                        setGraphActiveTemporal([
                          ...graphActiveTemporal.filter(
                            (g) => g !== _graph.value,
                          ),
                        ]);
                      } else {
                        setGraphActiveTemporal([
                          ...graphActiveTemporal,
                          _graph.value,
                        ]);
                        track("Graphic variable selected", {
                          Variable_ID: _graph.value,
                        });
                      }
                    }}
                  >
                    <div
                      className={`label-small-medium content-default-primary pe-pointer ${theme}`}
                    >
                      {
                        language[
                          `energy_graph_label_${_graph.value.toLowerCase()}`
                        ]
                      }{" "}
                      {_graph.unit && `(${_graph.unit})`}
                    </div>
                    <i
                      className={`fa-check-circle ${theme} ${
                        isSelected ? "content-color-green fa-solid" : "content-default-secondary fal"
                      } txt-10`}
                    ></i>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={`row-end pt-3 px-1 border-fill-medium-top ${theme}`}
          >
            <button
              className={`p-2 rounded-16 label-small-regular button-fill-brand button-content-equal ${theme}`}
              onClick={() => {
                if (!areArrayEquals(activeGraphs, graphActiveTemporal)) {
                  setGraphActive(graphActiveTemporal);
                }
                setShow(false);
                setControlRemoteClick(false);
                setShowSubmenuGraphs("");
              }}
            >
              Hecho
            </button>
          </div>
        </div>
      }
    >
      <i
        className={`fa-circle-chevron-right txt-10 pe-pointer ${
          show || showSubmenuGraphs === graphData[0].group
            ? "content-color-green fa-solid"
            : isDisabled
            ? "content-default-tertiary fa-light"
            : "content-default-secondary fa-light"
        } ${theme}`}
      ></i>
    </Popover>
  );
};

export default SubMenuGraphs;
