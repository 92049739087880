import React from "react"

import ThemedImage from "../utils/ThemedImage"

export const stepsService = (language, theme) => {
  return (
    [
      {        
        content: (
          <div className={`column-center align-items-center gap-2 w-100 rounded-8 mt-3 surface-bg-fill ${theme}`} style={{ height: 150 }}>
            <div style={{ height: "35%" }}>
              <div className={`row-center align-items-center alisurface-bg-on-surface border-fill-medium rounded-pill overflow-hidden mt-2 ${theme}`} style={{width: 60, height: 60}}>
                <i className={`fa-light fa-circle-plus txt-16 content-default-primary ${theme}`} />              
              </div>
            </div>          
          </div>
        ),
        title: language.onboarding_service_title_step1,
        description: language.onboarding_service_description_step1,
        placement: "auto",
        target: "#menu_services",
        disableBeacon: true,
        steps: 3,
      },
      {
        content: (
          <div className="row-center align-items-center w-100 mt-3" style={{ height: 152, width: 300 }}>
            <ThemedImage imageName={'step2-service-detail.png'}/>
          </div>
        ),
        title: language.onboarding_service_title_step2,
        description: language.onboarding_service_description_step2,
        placement: "auto",        
        target: "#service_picker",
        disableBeacon: true,
        steps: 3,
      },
      {        
        content:
          <div className={`column-center align-items-center w-100 rounded-8 mt-3 surface-bg-fill ${theme}`} style={{ height: 150 }}>
            <div className={`row-between align-items-center pe-pointer rounded-pill gap-3 p-2 max-width-200 min-height-33 surface-bg-on-surface ${theme}`}>
              <i className={`far fa-circle-chevron-down txt-8 content-default-tertiary ${theme}`}></i>
              <p className={`text-nowrap text-truncate label-small-regular content-default-primary ${theme}`}>{language.service_label_active_project_example}</p>
              <i className={`far fa-circle-xmark txt-8 content-default-tertiary ${theme}`}></i>
            </div>
          </div>,
        title: language.onboarding_service_title_step3,
        description: language.onboarding_service_description_step3,
        placement: "auto",        
        target: "#active_service",
        disableBeacon: true,
        steps: 3,
      }
    ]
  )
} 
