import React from 'react';

import ThemedImage from '../utils/ThemedImage';

export const stepsAlert = (language) => {
    return ([        
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 124, width: 300 }}>
                    <ThemedImage imageName={'step1-alerts.png'}/>
                </div>,
            title: language.onboarding_alert_title_step1,
            description: language.onboarding_alert_description_step1,
            placement: "left",            
            target: "#added_alert",
            disableBeacon: true,
            steps: 4,
            disableOverlayClose: true,
            spotlightClicks: true,
            hideFooter: true,
            hideFooterTitle: '¡Da click para descubrir cómo!',
            offset: 30,
            pulseEffect: true
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 124, width: 300 }}>
                    <ThemedImage imageName={'step2-alerts.png'}/>
                </div>,
            title: language.onboarding_alert_title_step2,
            description: language.onboarding_alert_description_step2,
            placement: "left",
            target: "#new_alert",
            disableBeacon: true,
            steps: 4,
            hideBackButton: true
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 134, width: 300 }}>
                    <ThemedImage imageName={'step3-alerts.png'}/>
                </div>,
            title: language.onboarding_alert_title_step3,
            description: language.onboarding_alert_description_step3,
            placement: "left",
            target: "#new_alert",
            disableBeacon: true,
            steps: 4
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 134, width: 300 }}>
                    <ThemedImage imageName={'step4-alerts.png'}/>
                </div>,
            title: language.onboarding_alert_title_step4,
            description: language.onboarding_alert_description_step4,
            placement: "left",
            target: "#new_alert",
            disableBeacon: true,
            steps: 4
        }        
    ])
};