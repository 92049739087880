import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import * as invoicesActions from '../../store/actions/Invoices';

import { sortByDate } from '../../services/CalendarHandler';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import QRCode from "react-qr-code";

import signature from '../../imgs/jeh-signature.png';

import { Whisper, Tooltip } from 'rsuite';

import TooltipInfo from '../shared/utilities/Tooltip';

const progressStyle = {
    path: { stroke: "#a1c52a" },
    trail: { stroke: "#f2f2f3" }
}

const InvoiceDetails = (props) => {

    let invoiceDetailsContent = props.invoiceDetailsContent;
    let invoiceDetails = props.invoiceDetails;
    let invoiceShow = props.invoiceShow;

    const loadInvoiceDetailsContent = props.loadInvoiceDetailsContent;

    const groupConceptsByServiceType = (concepts) => {
        return concepts.reduce((result, concept) => {
            const serviceType = concept.service_type;
            if (!result[serviceType]) {
                result[serviceType] = [];
            }
            result[serviceType].push(concept);
            return result;
        }, {});
    };

    useEffect(() => {       
        if (invoiceDetails !== null && invoiceShow?.type_content !== 'pdf') { loadInvoiceDetailsContent() }
    }, [loadInvoiceDetailsContent, invoiceDetails, invoiceShow]);

    return (
        <Fragment>
            {invoiceDetailsContent !== null ?
                <section key={invoiceDetailsContent.id_bill} className="w-100" style={{ maxHeight: '100%' }}>
                    <div className="position-relative column-between align-items-center bg-white">                        
                        <div className="column-start align-items-start w-100">
                            {invoiceDetailsContent.content.map((c, index) => (<Fragment key={index}>
                                <div className="position-absolute right-0 txt-2 txt-phrase txt-regular txt-black vertical-banner-invoice">{parseInt(c.bill.match(/e(\d+)/i)[1], 10) < 40000 ? 'Impreso por CENET SA NIT 830057860 - MISFACTURAS | Resolución 18764066719668 aprobado en 20240304 prefijo NFE desde número 33416 al 40000 vigencia 12 meses' : 'Impreso por CENET SA NIT 830057860 - MISFACTURAS | Resolución 18764073131869 aprobado en 20240617 prefijo NFE desde número 40001 al 100000 vigencia 24 meses'}</div>
                                <div className="row-between align-items-center bg-black w-100 py-3">
                                    <div className='column-center align-items-center'>
                                        <img src="https://neu-public.s3-sa-east-1.amazonaws.com/images/Logo-background-black-small.png" alt="logo-neu-energy" className="ms-3" style={{ width: 60 }} />
                                        <a href="https://www.neu.com.co" style={{ textDecoration: 'none' }} className='text-center txt-title txt-green-550 txt-6 ms-3'>neu.com.co</a>
                                    </div>
                                    <div className="row-between align-items-center bg-gray-500 px-5 py-1 mx-3 rounded-8">
                                        <div className="column-start align-items-end">
                                            <div className="txt-white txt-phrase txt-6 txt-regular text-right">{props.language.invoice_content_text_bill}</div>
                                            <div className="txt-green-500 txt-phrase txt-6 txt-bold text-right">{c.bill}</div>
                                        </div>
                                        <i className="fa-light fa-file-invoice txt-12 txt-green-500 ms-3"></i>
                                    </div>
                                </div>
                                <div className="row-between align-items-stretch w-100 my-2 px-4">
                                    <div className="column-start col-6">
                                        <div className="row-start align-center">
                                            <div className="bg-gray-50 py-2 px-2 rounded-8">
                                                <div className="txt-phrase txt-regular txt-6 txt-gray-500">{props.language.invoice_content_text_contract}</div>
                                                <div className="txt-phrase txt-bold txt-6 txt-black">{c.contract}</div>
                                            </div>
                                            <div className="bg-gray-50 py-2 px-2 rounded-8 flex-grow-1 ms-1">
                                                <div className="txt-phrase txt-regular txt-6 txt-gray-500">{props.language.invoice_content_text_niu}</div>
                                                <div className="txt-phrase txt-bold txt-6 txt-black">{c.niu}</div>
                                            </div>
                                        </div>
                                        <div className="row-start align-center my-1">
                                            <div className="bg-gray-50 py-2 px-2 rounded-8 flex-grow-1">
                                                <div className="txt-phrase txt-regular txt-6 txt-gray-500">{props.language.invoice_content_text_customer_name}</div>
                                                <div className="txt-phrase txt-bold txt-6 txt-black max-width-200">{c.customer_name}</div>
                                            </div>
                                            <div className="bg-gray-50 py-2 px-2 rounded-8 ms-1">
                                                <div className="txt-phrase txt-regular txt-6 txt-gray-500">{c.type_id}</div>
                                                <div className="txt-phrase txt-bold txt-6 txt-black">{c.id}</div>
                                            </div>
                                        </div>
                                        <div className="row-start align-center">
                                            <div className="bg-gray-50 py-2 px-2 rounded-8 me-1">
                                                <div className="txt-phrase txt-regular txt-6 txt-gray-500">{props.language.invoice_content_text_service_name}</div>
                                                <div className="txt-phrase txt-bold txt-6 txt-black w-100 text-wrap">{c.service_name}</div>
                                            </div>
                                            <div className="bg-gray-50 py-2 px-2 rounded-8 flex-grow-1">
                                                <div className="txt-phrase txt-regular txt-6 txt-gray-500">{props.language.invoice_content_text_billing_address}</div>
                                                <div className="txt-phrase txt-bold txt-6 txt-black w-100 text-wrap">{c.billing_address}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column-start col-6 ps-4">
                                        <div className="position-relative column-center align-items-center flex-grow-1">
                                            <div className={`position-absolute bg-${!invoiceDetailsContent.pending.length ? 'green-blue-gradient' : 'red-orange-gradient'} rounded-10 w-100 h-100`}></div>
                                            <div className="position-absolute column-start w-100 h-100">
                                                <div className="column-center bg-white rounded-8 flex-grow-1" style={{ margin: 2 }}>
                                                    <div className="txt-phrase txt-bold txt-8 txt-neutral-800 text-center">{`${props.language.invoice_content_text_total} ${c.bill}`}</div>
                                                    <div className={`txt-title txt-bold txt-12 txt-${!invoiceDetailsContent.pending.length ? 'green-550' : 'orange-500'} text-center`}>${c.total}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="position-relative row-between align-items-center mt-1" style={{ height: 42 }}>
                                            <div className="h-100" style={{ width: '48.5%' }}>
                                                <div className="position-relative column-center align-items-center w-100 h-100">
                                                    <div className="position-absolute bg-purple-gradient rounded-10 w-100 h-100"></div>
                                                    <div className="position-absolute column-start w-100 h-100">
                                                        <div className="row-start align-items-center bg-white rounded-8 flex-grow-1" style={{ margin: 2 }}>
                                                            <i className="fa-solid fa-bolt txt-10 txt-purple-500 mx-3"></i>
                                                            <div className="column-start">
                                                                <div className="txt-phrase txt-regular txt-6 txt-purple-500 text-left w-100">{props.language.invoice_content_text_bill_date}</div>
                                                                <div className="txt-phrase txt-bold txt-6 txt-purple-500 text-left" style={{ textOverflow: "unset" }}>
                                                                    {c.bill_date}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="h-100" style={{ width: '48.5%' }}>
                                                <div className="position-relative column-center align-items-center w-100 h-100">
                                                    <div className="position-absolute bg-red-orange-gradient rounded-10 w-100 h-100"></div>
                                                    <div className="position-absolute column-start w-100 h-100">
                                                        <div className="row-start align-items-center bg-white rounded-8 flex-grow-1" style={{ margin: 2 }}>
                                                            <i className="fa-solid fa-warning txt-10 txt-orange-500 mx-3"></i>
                                                            <div className="column-start">
                                                                <div className="txt-phrase txt-regular txt-6 txt-orange-500 text-left w-100">{props.language.invoice_content_text_due_date}</div>
                                                                <div className="txt-phrase txt-bold txt-6 txt-orange-500 text-left" style={{ textOverflow: "unset" }}>
                                                                    {c.due_date}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-between align-items-stretch w-100 px-4">
                                    <div className="col-6 h-100">
                                        {!invoiceDetailsContent.pending.length ?
                                            <div className="row-start align-items-center bg-green-200 p-2 rounded-8">
                                                <i className="fa-regular fa-face-laugh-beam txt-12 txt-green-900 me-2"></i>
                                                <div className="txt-green-900 txt-phrase txt-6 txt-regular text-right text-wrap"><strong>{props.language.invoice_content_text_strong_balance}</strong>{props.language.invoice_content_first_text_balance}<br />
                                                    {props.language.invoice_content_second_text_balance}</div>
                                            </div> :
                                            <div className='column-start w-100'>
                                                <div className="row-start align-items-center bg-red-200 p-2 rounded-8">
                                                    <i className="fa-solid fa-warning txt-10 txt-red-700 me-3"></i>
                                                    <div className="txt-red-700 txt-phrase txt-6 txt-regular text-right"><strong>{props.language.invoice_content_text_strong_outstanding_balance}</strong> {props.language.invoice_content_text_outstanding_balance} ${c.outstanding_balance}</div>
                                                </div>
                                                <div className="row-start align-items-center mt-2">
                                                    {invoiceDetailsContent.pending.length >= 4 ? invoiceDetailsContent.pending.slice(4).map((p, index) => (<div key={index} className="txt-phrase txt-bold txt-red-500 txt-6 border-1 border-color-red-500 px-2 py-1 me-2 rounded-30">{p.bill}</div>)) : invoiceDetailsContent.pending.map((p, index) => (<div key={index} className="txt-phrase txt-bold txt-red-500 txt-6 border-1 border-color-red-500 px-2 py-1 me-2 rounded-30">{p.bill}</div>))}
                                                    {invoiceDetailsContent.pending.length >= 4 ? <div key={index} className="txt-phrase txt-bold txt-red-500 txt-6 border-1 border-color-red-500 px-2 py-1 me-2 rounded-30">{`+${invoiceDetailsContent.pending.length - 4}`}</div> : null}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="column-start col-6 ps-4">
                                        <div className="position-relative column-center align-items-center flex-grow-1">
                                            <div className="position-absolute bg-red-orange-gradient rounded-10 w-100 h-100"></div>
                                            <div className="position-absolute column-start w-100 h-100">                                                
                                                {c.suspension_date === 'Suspendido' ? 
                                                    <div className="column-center bg-red-200 rounded-8 flex-grow-1" style={{ margin: 2 }}>
                                                        <div className="txt-phrase txt-regular txt-6 txt-red-700 text-center px-2 text-wrap">
                                                            {props.language.invoice_content_text_outstanding_balance_suspension}</div>
                                                        <div className="txt-phrase txt-bold txt-8 txt-red-700 text-center w-100">{c.suspension_date}</div>
                                                    </div>:
                                                        c.suspension_date === null && c.outstanding_balance === "0" ?
                                                            <div className="column-center bg-white rounded-8 flex-grow-1" style={{ margin: 2 }}>
                                                                <div className="txt-phrase txt-regular txt-6 txt-red-700 text-center px-2 text-wrap">
                                                                    {props.language.invoice_content_text_suspension_date_empty}</div>                                                    
                                                            </div>:
                                                                <div className="column-center bg-white rounded-8 flex-grow-1" style={{ margin: 2 }}>
                                                                    <div className="txt-phrase txt-regular txt-6 txt-red-700 text-center px-2 text-wrap">
                                                                        {props.language.invoice_content_text_suspension_date}</div>
                                                                    <div className="txt-phrase txt-bold txt-8 txt-red-700 text-center w-100">{c.suspension_date}</div>
                                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row-between align-items-stretch w-100 px-4 pt-2'>
                                    <div className="w-100 h-100">
                                        {!invoiceDetailsContent.pending.length ?
                                            <div className="row-start align-items-center rounded-8">
                                                <div className="txt-phrase txt-6 txt-regular text-right text-wrap"></div>
                                            </div> :
                                            <div className='column-start w-100'>
                                                <div className="row-start align-items-center border-1 border-color-red-500 p-2 rounded-8">
                                                    <i className="fa fa-exclamation-circle txt-10 txt-red-700 me-3"></i>
                                                    <div className="txt-black txt-phrase txt-6 txt-regular text-right"><strong className='txt-orange-500'>¡Estas a punto de perder tu beneficio de contribución!</strong> Recuerda enviar la información actualizada al correo soporte@erco.energy</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row-between align-items-center w-100 py-3">
                                    <div className="txt-black txt-title txt-8 txt-bold ms-4">{props.language.invoice_content_text_consumption_detail}</div>
                                </div>
                                <div className="row-between w-100 px-4">
                                    <div className="col-3">
                                        <div className="bg-gray-50 py-2 rounded-8">
                                            <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_consumption}</div>
                                            <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.consumption} kWh</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row-between align-items-center bg-gray-50 py-2 mx-1 rounded-8">
                                            <div className="col-4">
                                                <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_days_billed}</div>
                                                <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.days_billed}</div>
                                            </div>
                                            <div className="col-4">
                                                <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_billed_since}</div>
                                                <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.billed_since}</div>
                                            </div>
                                            <div className="col-4">
                                                <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_billed_until}</div>
                                                <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.billed_until}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="bg-gray-50 py-2 rounded-8">
                                            <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_meter_code}</div>
                                            <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.meter_code}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-between w-100 mt-2 px-4">
                                    <div className="col-12">
                                        <div className="border-1 border-color-gray-200 p-2 rounded-8">
                                            <div className="row-between align-items-center">
                                                <div className="row-start align-items-center">
                                                    <i className="fa-regular fa-arrow-left txt-10 txt-black me-2"></i>
                                                    <div>
                                                        <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_previous_consumption}</div>
                                                        <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.previous_consumption} kWh
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-start align-items-center">
                                                    <i className="fa-regular fa-arrow-down txt-10 txt-black me-2"></i>
                                                    <div className="w-100 column-between align-items-center">
                                                        <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_current_consumption}</div>
                                                        <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.consumption} kWh
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-start align-items-center">
                                                    <i className="fa-regular fa-arrows-cross txt-10 txt-black me-2"></i>
                                                    <div>
                                                        <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">
                                                            {props.language.invoice_content_text_avg_six_months}</div>
                                                        <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.avg_6_months} kWh
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column-end" style={{ height: 200 }}>
                                                <div className="row-between align-items-stretch">
                                                    {invoiceDetailsContent.graph.sort(sortByDate).map((g, index) => (<div key={index} className="column-end align-items-center" style={{ width: 100 }}>
                                                        <div className="txt-phrase txt-bold txt-6 txt-blue-500">${g.total}</div>
                                                        <div className="txt-phrase txt-bold txt-6 txt-green-550">{g.quantity}</div>
                                                        <div className="bg-green-550 rounded-8 my-1" style={{ height: g.pixels, width: 50 }}>
                                                        </div>
                                                        <div className="txt-title txt-bold txt-6 txt-black">{g.month}</div>
                                                    </div>))}
                                                </div>
                                            </div>
                                            <div className="row-start align-items-center mt-2">
                                                <div className="row-start align-items-center">
                                                    <i className="fa-regular fa-money-bill txt-10 txt-blue-500"></i>
                                                    <div className="txt-title txt-bold txt-6 txt-blue-500 ms-2">{props.language.invoice_content_text_label_pay_month}</div>
                                                </div>
                                                <div className="row-start align-items-center ms-5">
                                                    <i className="fa-solid fa-bolt txt-10 txt-green-550"></i>
                                                    <div className="txt-title txt-bold txt-6 txt-green-550 ms-2">{props.language.invoice_content_text_label_consumption}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="txt-black txt-title txt-8 txt-bold ms-4 my-2">{props.language.invoice_content_text_reactive_detail}</div>
                                <div className="row-between w-100 px-4">
                                    <div className="col-3">
                                        <div className="bg-gray-50 py-2 rounded-8">
                                            <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_days_billed_reactive}</div>
                                            <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.days_billed_reactive}</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row-between align-items-center bg-gray-50 py-2 mx-1 rounded-8">
                                            <Whisper
                                                delay={100}
                                                placement="bottom"
                                                trigger="hover"
                                                speaker={<Tooltip>{props.language.tooltip_reactive_inductive}</Tooltip>}>
                                                <div className="col-4">
                                                    <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_amount_billed_reactive_inductive}</div>
                                                    <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.amount_billed_reactive_inductive}</div>
                                                </div>
                                            </Whisper>
                                            <Whisper
                                                delay={100}
                                                placement="bottom"
                                                trigger="hover"
                                                speaker={<Tooltip>{props.language.tooltip_reactive_capacitive}</Tooltip>}>
                                                <div className="col-4">
                                                    <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_amount_billed_reactive_capacitive}</div>
                                                    <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.amount_billed_reactive_capacitive}</div>
                                                </div>
                                            </Whisper>
                                            <div className="col-4">
                                                <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_amount_billed_reactive}</div>
                                                <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.amount_billed_reactive}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="bg-gray-50 py-2 rounded-8">
                                            <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_reactive_factor}</div>
                                            <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.reactive_factor}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="txt-black txt-title txt-8 txt-bold mx-4 my-3">{props.language.invoice_content_text_tariff_detail}</div>
                                <div className="txt-black txt-phrase txt-6 txt-regular mx-4 text-wrap" style={{ textAlign: 'justify' }}>
                                    {props.language.invoice_content_first_text_tariff} <strong className="txt-green-550">{props.language.invoice_content_first_text_strong_tariff}</strong> {props.language.invoice_content_second_text_tariff} <strong>{props.language.invoice_content_second_text_strong_tariff}</strong>, {props.language.invoice_content_third_text_tariff} <strong>{props.language.invoice_content_third_text_strong_tariff} {c.voltage_level}</strong>, {props.language.invoice_content_fourth_text_tariff} <strong>{props.language.invoice_content_fourth_text_strong_tariff} {c.social_stratum}</strong>, {props.language.invoice_content_fifth_text_tariff}
                                    <strong>{c.asset_ownership}</strong> {props.language.invoice_content_sixth_text_tariff} <strong>{c.location_type} {c.tariff_type}</strong>
                                </div>
                                <div className="row-between align-items-end px-4 py-3 w-100">
                                    <div className="column-start align-items-center">
                                        <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={(parseInt(c.g) / parseInt(c.cu)) * 100}
                                                strokeWidth={12}
                                                styles={progressStyle}
                                            >
                                                <i className="fa-regular fa-solar-panel txt-black txt-14"></i>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                        <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">G</div>
                                        <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">{props.language.invoice_content_text_tariff_g}</div>
                                        <div className="txt-green-550 txt-phrase txt-6 txt-bold text-center w-100">${c.g}</div>
                                    </div>
                                    <div className="column-start align-items-center">
                                        <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={(parseInt(c.t) / parseInt(c.cu)) * 100}
                                                strokeWidth={12}
                                                styles={progressStyle}
                                            >
                                                <i className="fa-regular fa-utility-pole txt-black txt-14"></i>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                        <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">T</div>
                                        <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">{props.language.invoice_content_text_tariff_t}</div>
                                        <div className="txt-green-550 txt-phrase txt-6 txt-bold text-center w-100">${c.t}</div>
                                    </div>
                                    <div className="column-start align-items-center">
                                        <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={(parseInt(c.d) / parseInt(c.cu)) * 100}
                                                strokeWidth={12}
                                                styles={progressStyle}
                                            >
                                                <i className="fa-regular fa-transformer-bolt txt-black txt-14"></i>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                        <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">D</div>
                                        <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">{props.language.invoice_content_text_tariff_d}</div>
                                        <div className="txt-green-550 txt-phrase txt-6 txt-bold text-center w-100">${c.d}</div>
                                    </div>
                                    <div className="column-start align-items-center">
                                        <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={(parseInt(c.cv) / parseInt(c.cu)) * 100}
                                                strokeWidth={12}
                                                styles={progressStyle}
                                            >
                                                <i className="fa-regular fa-meter-bolt txt-black txt-14"></i>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                        {c.cot ? 
                                            <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">Cv + COT</div> : 
                                            <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">Cv</div>}
                                        <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">{props.language.invoice_content_text_tariff_cv}</div>
                                        <div className="txt-green-550 txt-phrase txt-6 txt-bold text-center w-100">${c.cv}</div>
                                    </div>
                                    <div className="column-start align-items-center">
                                        <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={(parseInt(c.pr) / parseInt(c.cu)) * 100}
                                                strokeWidth={12}
                                                styles={progressStyle}
                                            >
                                                <i className="fa-regular fa-route txt-black txt-14"></i>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                        <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">P</div>
                                        <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">{props.language.invoice_content_text_tariff_pr}</div>
                                        <div className="txt-green-550 txt-phrase txt-6 txt-bold text-center w-100">${c.pr}</div>
                                    </div>
                                    <div className="column-start align-items-center">
                                        <div style={{ height: 70, width: 70 }}>
                                            <CircularProgressbarWithChildren
                                                value={(parseInt(c.r) / parseInt(c.cu)) * 100}
                                                strokeWidth={12}
                                                styles={progressStyle}
                                            >
                                                <i className="fa-regular fa-signs-post txt-black txt-14"></i>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                        <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">R</div>
                                        <div className="txt-black txt-phrase txt-6 txt-bold text-center w-100">{props.language.invoice_content_text_tariff_r}</div>
                                        <div className="txt-green-550 txt-phrase txt-6 txt-bold text-center w-100">${c.r}</div>
                                    </div>
                                </div>
                                <div className='px-4 w-100'>
                                    <div className="row-between align-items-center bg-gray-50 p-2 w-100 rounded-8">
                                        <div className="row-start txt-title txt-8 txt-bold txt-neutral-800">
                                            <p className='me-2'>CU</p><strong className="txt-green-550">=</strong>
                                            <p className='mx-2'>G</p><strong className="txt-green-550">+</strong>
                                            <p className='mx-2'>T</p><strong className="txt-green-550">+</strong>
                                            <p className='mx-2'>D</p><strong className="txt-green-550">+</strong>
                                            {c.cot ? <p className='mx-2'>CV + COT</p> : <p className='mx-2'>CV</p>}<strong className="txt-green-550">+</strong>
                                            <p className='mx-2'>P</p><strong className="txt-green-550">+</strong>
                                            <p className='ms-2'>R</p>
                                        </div>
                                        <div className="txt-black txt-phrase txt-8 txt-bold txt-neutral-800">{c.cot ? props.language.invoice_content_text_tariff_cu_cot : props.language.invoice_content_text_tariff_cu} = ${c.cu}</div>
                                    </div>
                                </div>
                                <div className="txt-black txt-title txt-8 txt-bold my-3 mx-4">{props.language.invoice_content_text_technical_info}</div>
                                <div className="row-between align-items-center w-100 px-4">
                                    <div className="col-3">
                                        <div className="bg-gray-50 rounded-8 py-2 px-2 me-1">
                                            <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_or}
                                            </div>
                                            <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.or}</div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="bg-gray-50 rounded-8 py-2 px-2 me-1">
                                            <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_or_phone}</div>
                                            <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.or_phone}</div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="bg-gray-50 rounded-8 py-2 px-2 me-1">
                                            <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_distr_circuit}</div>
                                            <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.distr_circuit}</div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="bg-gray-50 rounded-8 py-2 px-2">
                                            <div className="txt-phrase txt-regular txt-6 txt-gray-400 text-center w-100">{props.language.invoice_content_text_transf_number}</div>
                                            <div className="txt-title txt-bold txt-6 txt-black text-center w-100">{c.transf_number}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-between align-items-center w-100 py-3 px-4">
                                    <div className="col-6">
                                        <div className="txt-black txt-title txt-8 txt-bold">{props.language.invoice_content_text_legal_info}</div>
                                        <div className="txt-black txt-phrase txt-regular mt-2 text-wrap txt-2"
                                            style={{ textAlign: 'justify' }}>
                                            {props.language.invoice_content_first_text_legal_info}<br />
                                            {props.language.invoice_content_second_text_legal_info}<br />
                                            {props.language.invoice_content_third_text_legal_info} <a href="https://www.neu.com.co" style={{ textDecoration: 'none' }}>www.neu.com.co</a>, {props.language.invoice_content_fourth_text_legal_info}<br />
                                            {props.language.invoice_content_fifth_text_legal_info} <a href="https://www.neu.com.co" style={{ textDecoration: 'none' }}>www.neu.com.co</a> {props.language.invoice_content_sixth_text_legal_info}<br />
                                            {props.language.invoice_content_seventh_text_legal_info}<br />
                                            {props.language.invoice_content_eighth_text_legal_info}
                                        </div>
                                    </div>
                                    <div className="col-6 ps-4">
                                        <div className="py-2 px-3 bg-black rounded-8">
                                            <div className="txt-white txt-title txt-8 txt-bold">{props.language.invoice_content_text_pay_methods}</div>
                                            <div className="row-start align-items-end mt-2 pe-pointer">
                                                <i className="fa-light fa-desktop txt-green-500 txt-8 pe-pointer"></i>
                                                <a className="txt-phrase ms-2 txt-green-500 txt-bold txt-6 pe-pointer" href="https://www.neu.com.co" style={{ textDecoration: 'none' }}>{props.language.invoice_content_text_pay_web}</a>
                                            </div>
                                            <div className="row-start align-items-end my-2 pe-pointer">
                                                <i className="fa-light fa-mobile txt-green-500 txt-8 pe-pointer"></i>
                                                <a className="txt-phrase ms-2 txt-green-500 txt-bold txt-6 pe-pointer" href="https://app.enrg.ai/invoices" style={{ textDecoration: 'none' }}>{props.language.invoice_content_text_pay_app}</a>
                                            </div>
                                            <div className="row-start align-items-end">
                                                <i className="fa-light fa-building-columns txt-white txt-8"></i>
                                                <div className="txt-phrase ms-2 txt-white txt-bold txt-6">{props.language.invoice_content_text_pay_wire_transfer}</div>
                                            </div>
                                            <div className="txt-phrase txt-regular txt-white mt-2 txt-6 text-wrap">{props.language.invoice_content_first_text_pay_wire_transfer_detail} <strong className="txt-bold txt-green-500">37700011858</strong> {props.language.invoice_content_second_text_pay_wire_transfer_detail} <strong
                                                className="txt-bold txt-green-500">cartera-clientes@neu.com.co</strong> relacionando el código de la facturas (NFEXXXX) correspondientes.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-between align-items-center w-100 px-4">
                                    <div className="col-6">
                                        <div className="row-start align-items-start">
                                            <QRCode
                                                size={256}
                                                style={{ height: 75, width: 75 }}
                                                value={c.qr}
                                            />
                                            <div className="column-start align-items-start ms-3">
                                                <img src={signature} alt="jeh-signature" style={{ width: 110, height: 30 }} />
                                                <div className="txt-black txt-phrase txt-6 txt-regular"><strong>Juan Esteban Hincapie</strong></div>
                                                <div className="txt-gray-400 txt-phrase txt-6 txt-regular">{props.language.invoice_content_text_legal_representative}</div>
                                                <div className="txt-green-550 txt-phrase txt-6 txt-regular"><strong>NEU Energy S.A.S E.S.P</strong></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 ps-4">
                                        <div className="txt-black txt-title txt-6 txt-bold">NEU ENERGY S.A.S E.S.P - 901302727-0</div>
                                        <div className="txt-black txt-phrase txt-6 txt-regular my-1">{props.language.invoice_content_text_taxes}
                                        </div>
                                        <div className="txt-black txt-phrase txt-6 txt-regular"><strong>{props.language.invoice_content_text_address}:</strong> Carrera 49 #61SUR - 540</div>
                                        <div className="txt-black txt-phrase txt-6 txt-regular"><strong>{props.language.invoice_content_text_payment_way}:</strong> {props.language.invoice_content_text_payment_cash}
                                        </div>
                                        <div className="txt-black txt-phrase txt-6 txt-regular"><strong>{props.language.invoice_content_text_payment_method}:</strong> {props.language.invoice_content_text_debit_card}</div>
                                        <div className="txt-black txt-phrase txt-6 txt-regular"><strong>Autorretenedor ICA en Sabaneta, Piedecuesta, Santa Marta, y Agustín Codazzi</strong></div>
                                    </div>
                                </div>
                                <div className="txt-black txt-phrase txt-regular text-center px-3 py-2 txt-6 w-100">CUFE: {c.cufe}
                                </div></Fragment>))}
                            <div className="row-between align-items-center bg-gray-50 w-100 py-3">
                                <div className="row-start align-items-center ms-3">
                                    <div className="row-start align-items-center">
                                        <i className="fa-regular fa-comments-question-check txt-12 txt-green-550 me-2"></i>
                                        <div>
                                            <div className="txt-phrase txt-regular txt-6 txt-gray-500 text-left w-100">{props.language.invoice_content_text_support}</div>
                                            <div className="txt-phrase txt-bold txt-6 txt-black text-left w-100">soporte@erco.energy
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-start align-items-center ms-2 ps-2">
                                        <i className="fa-brands fa-whatsapp txt-12 txt-green-550 me-2"></i>
                                        <div>
                                            <div className="txt-phrase txt-regular txt-6 txt-gray-500 text-left w-100">{props.language.invoice_content_text_support_wsp}</div>
                                            <div className="txt-phrase txt-bold txt-6 txt-black text-left w-100">(+57) 601 6659652</div>
                                        </div>
                                    </div>
                                    <div className="row-start align-items-center ms-2 ps-2">
                                        <i className="fa-regular fa-light-emergency-on txt-12 txt-green-550 me-2"></i>
                                        <div>
                                            <div className="txt-phrase txt-regular txt-6 txt-gray-500 text-left w-100">{props.language.invoice_content_text_support_emergency}</div>
                                            <div className="txt-phrase txt-bold txt-6 txt-black text-left w-100">(+57) 601 6659652</div>
                                        </div>
                                    </div>
                                    <img style={{ height: 25, width: 57 }} className="ms-3" src="https://neu-public.s3-sa-east-1.amazonaws.com/images/Superservicios.png" alt='' />
                                </div>
                                <div className="txt-title txt-bold txt-6 txt-black me-3">{props.language.invoice_content_text_footer_page} 1/2</div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative column-between align-items-center bg-white mt-4" style={{ minHeight: 1440 }}>
                        <div className="position-absolute right-0 txt-2 txt-phrase txt-regular txt-black vertical-banner-invoice">Impreso por CENET SA NIT 830057860 - MISFACTURAS | Resolución 18764045415051 aprobado en 20230303 prefijo NFE desde número 15001 al 40000 vigencia 12 meses</div>
                        <div className="column-start align-items-start w-100">
                            {invoiceDetailsContent.content.map((c, index) => (
                                <div key={index} className="row-between align-items-center w-100 py-3 px-4">
                                    <div className="row-between h-100">
                                        <div className="row-start align-items-center bg-gray-50 p-2 rounded-8">
                                            <img src="https://neu-public.s3.sa-east-1.amazonaws.com/images/bills/logo-neu-card.svg" alt="logo-neu-card" style={{ width: 60 }} />
                                            <div className="txt-black txt-phrase txt-6 txt-regular ms-3 text-wrap">
                                                <strong>{props.language.invoice_content_text_strong_neugets}</strong> {props.language.invoice_content_first_text_neugets} <strong className="txt-green-550">{c.neugets} NEUGets</strong><br />{props.language.invoice_content_second_text_neugets}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-between align-items-center bg-gray-500 px-5 py-1 rounded-8">
                                        <div className="column-start align-items-end">
                                            <div className="txt-white txt-phrase txt-6 txt-regular text-right">{props.language.invoice_content_text_bill}</div>
                                            <div className="txt-green-500 txt-phrase txt-6 txt-bold text-right">{c.bill}</div>
                                        </div>
                                        <i className="fa-light fa-file-invoice txt-14 txt-green-500 ms-3"></i>
                                    </div>
                                </div>))}
                            <div className="row-between align-items-center w-100 pb-3 px-4">
                                <div className="txt-black txt-title txt-8 txt-bold">{props.language.invoice_content_text_bill_detail}</div>
                                <div className="row-between align-items-center bg-green-500 px-2 py-2 rounded-8 pe-pointer">
                                    <i className="fa-regular fa-mobile txt-12 txt-neutral-800 pe-pointer me-2"></i>
                                    <a className="txt-neutral-800 txt-phrase txt-8 txt-bold text-right pe-pointer" href="https://app.enrg.ai/invoices" style={{ textDecoration: 'none' }}>{props.language.invoice_content_text_bill_detail_info}</a>
                                </div>
                            </div>
                            <div className="column-start align-items-center px-4 w-100">
                                {Object.entries(groupConceptsByServiceType(invoiceDetailsContent.concepts)).map(([serviceType, items], index) => (<Fragment key={index}>
                                    <div className="row-start align-items-center bg-black py-2 w-100 rounded-8 w-100 mt-2">
                                        <i className={`${serviceType === 'SALDOS A FAVOR DDV' || serviceType === 'SALDOS A FAVOR' ? 'fak fa-energy-symbol' : serviceType.split(" (")[0] === 'INTERESES DE MORA' ? 'fal fa-coin' : `fak fa-${serviceType.toLocaleLowerCase()}-symbol`} txt-green-500 txt-10 ms-3`}></i>
                                        <div className="txt-white txt-title txt-8 txt-bold ms-3">{serviceType}</div>
                                    </div>
                                    <div className="row-start align-items-center py-1 w-100">
                                        <div className="col-1">
                                            <div className="txt-title txt-bold txt-6 txt-black text-start ms-2">{props.language.invoice_content_text_header_table_item}</div>
                                        </div>
                                        <div className="col-5">
                                            <div className="txt-title txt-bold txt-6 txt-black text-start">{props.language.invoice_content_text_header_table_detail}</div>
                                        </div>
                                        <div className="col-2">
                                            <div className="txt-title txt-bold txt-6 txt-black text-start">{serviceType === 'SALDOS A FAVOR DDV' ? props.language.invoice_content_text_header_table_month_value : serviceType.split(" (")[0] === 'INTERESES DE MORA' ? props.language.invoice_content_text_header_table_concept_value : props.language.invoice_content_text_header_table_unit_value}</div>
                                        </div>
                                        <div className="col-2">
                                            <div className="txt-title txt-bold txt-6 txt-black text-center">{serviceType.split(" (")[0] === 'INTERESES DE MORA' ? props.language.invoice_content_text_header_table_days_quantity : props.language.invoice_content_text_header_table_quantity}</div>
                                        </div>
                                        <div className="col-2">
                                            <div className="txt-title txt-bold txt-6 txt-black text-end me-2">{props.language.invoice_content_text_header_table_value}</div>
                                        </div>
                                    </div>
                                    {items.map((cp, conceptIndex) => (
                                        <div key={conceptIndex} className={`row-start align-items-center txt-black py-1 w-100 rounded-8 pe-pointer ${conceptIndex % 2 === 0 ? 'bg-gray-90' : 'bg-white'}`}>
                                            <div className="col-1">
                                                <div className="txt-phrase txt-bold txt-6 txt-black text-start pe-pointer ms-2">{cp.number}</div>
                                            </div>
                                            <div className="col-5">
                                                <div className='row-start align-items-center gap-1'>
                                                    {props.language[`tooltip_${cp.code.replace(/\./g, '_').toLocaleLowerCase()}`] !== "" ?
                                                        <TooltipInfo
                                                            text={props.language[`tooltip_${cp.code.replace(/\./g, '_').toLocaleLowerCase()}`]}
                                                            changeFocus={() => { }}
                                                            fontSize={6}
                                                            fontIcon={6}
                                                            typeIcon={'solid'}
                                                            colorIcon={'black'}
                                                            bgColor={'gray-550'}
                                                        /> : null}
                                                    <div className="txt-phrase txt-bold txt-6 txt-black text-start pe-pointer">{serviceType === 'SALDOS A FAVOR DDV' ? cp?.item.replace(/\s*\(.*?\)\s*/g, '').trim() : cp.item}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="txt-phrase txt-regular txt-6 txt-black text-start pe-pointer">{serviceType === 'SALDOS A FAVOR DDV' ? cp?.item.match(/\(([^)]+)\)/)[1] : `$${cp.unit_value}`}</div>
                                            </div>
                                            <div className="col-2">
                                                <div className="txt-phrase txt-regular txt-6 txt-black text-center pe-pointer">{cp.quantity}</div>
                                            </div>
                                            <div className="col-2">
                                                <div className="txt-phrase txt-bold txt-6 txt-black text-end pe-pointer me-2">${cp.total}</div>
                                            </div>
                                        </div>))}</Fragment>))}
                                {invoiceDetailsContent.content.map((c, index) => (
                                    <div key={index} className="row-between align-items-stretch py-2 w-100">
                                        <div className="col-12">
                                            <div className="column-start align-items-center bg-gray-50 p-2 rounded-8">
                                            <div className="row-start align-items-center py-1 w-100 rounded-8">
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-6 txt-black text-start ms-2">Cargos de facturación</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-6 txt-black text-end me-2">${c.charges}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-start align-items-center py-1 w-100 rounded-8">
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-6 txt-black text-start ms-2">{props.language.invoice_content_text_iva}</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-6 txt-black text-end me-2">${c.iva}
                                                        </div>
                                                    </div>
                                                </div>
                                                {c.negative_balance !== '0' ? <div className="row-start align-items-center py-1 w-100 rounded-8">
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-6 txt-black text-start ms-2">{props.language.invoice_content_text_negative_balance}</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-6 txt-black text-end me-2">${c.negative_balance}
                                                        </div>
                                                    </div>
                                                </div> : null}
                                                <div className="row-start align-items-center py-1 w-100 rounded-8">
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-6 txt-black text-start ms-2">{props.language.invoice_content_text_subtotal}</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-6 txt-black text-end me-2">${c.subtotal}
                                                        </div>
                                                    </div>
                                                </div>
                                                {c.capital &&
                                                    <div className="row-start align-items-center py-1 w-100 rounded-8">
                                                        <div className="col-6">
                                                            <div className="txt-title txt-bold txt-6 txt-black text-start ms-2">{props.language.invoice_content_text_capital_record}
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="txt-title txt-bold txt-6 txt-black text-end me-2">${c.payments_capital}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row-between align-items-center bg-green-550 py-2 w-100 rounded-8">
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-8 txt-white text-start ms-2">{`${props.language.invoice_content_text_total} de la factura ${c.bill}`}
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-8 txt-white text-end me-2">${c.total}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {c.new_negative_balance !== '0' ? <div className="column-start align-items-center bg-blue-100 p-2 rounded-8 mt-2">
                                                <div className="row-start align-items-center py-1 w-100 rounded-8">
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-6 txt-black text-start ms-2">{props.language.invoice_content_text_new_negative_balance}</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="txt-title txt-bold txt-6 txt-black text-end me-2">${c.new_negative_balance}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null}
                                        </div>

                                    </div>))}
                            </div>
                        </div>
                        <div className="row-between align-items-center bg-gray-50 w-100 py-3">
                            <div className="row-start align-items-center ms-3">
                                <div className="row-start align-items-center">
                                    <i className="fa-regular fa-comments-question-check txt-12 txt-green-550 me-2"></i>
                                    <div>
                                        <div className="txt-phrase txt-regular txt-6 txt-gray-500 text-left w-100">{props.language.invoice_content_text_support}</div>
                                        <div className="txt-phrase txt-bold txt-6 txt-black text-left w-100">soporte@erco.energy
                                        </div>
                                    </div>
                                </div>
                                <div className="row-start align-items-center ms-2 ps-2">
                                    <i className="fa-brands fa-whatsapp txt-12 txt-green-550 me-2"></i>
                                    <div>
                                        <div className="txt-phrase txt-regular txt-6 txt-gray-500 text-left w-100">{props.language.invoice_content_text_support_wsp}</div>
                                        <div className="txt-phrase txt-bold txt-6 txt-black text-left w-100">(+57) 601 6659652</div>
                                    </div>
                                </div>
                                <div className="row-start align-items-center ms-2 ps-2">
                                    <i className="fa-regular fa-light-emergency-on txt-12 txt-green-550 me-2"></i>
                                    <div>
                                        <div className="txt-phrase txt-regular txt-6 txt-gray-500 text-left w-100">{props.language.invoice_content_text_support_emergency}</div>
                                        <div className="txt-phrase txt-bold txt-6 txt-black text-left w-100">(+57) 601 6659652</div>
                                    </div>
                                </div>
                                <img style={{ height: 25, width: 57 }} className="ms-3" src="https://neu-public.s3-sa-east-1.amazonaws.com/images/Superservicios.png" alt='' />
                            </div>
                            <div className="txt-title txt-bold txt-6 txt-black me-3">{props.language.invoice_content_text_footer_page} 2/2</div>
                        </div>
                    </div>
                </section> : null
            }
        </Fragment>
    )
};

const mapStateToProps = (state) => ({
    language: state.shared.language,
    invoiceDetails: state.invoices.invoiceDetails,
    invoiceDetailsContent: state.invoices.invoiceDetailsContent,
    invoiceShow: state.invoices.invoiceDetails
});

const mapDispatchToProps = dispatch => ({
    loadInvoiceDetailsContent: () => dispatch(invoicesActions.loadInvoiceDetailsContent())
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);