import React from 'react';

import ThemedImage from '../utils/ThemedImage';

export const stepsInvoice = (language) => {
    return ([
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 124, width: 300 }}>
                    <ThemedImage imageName={'step1-invoices.png'}/>
                </div>,
            title: language.onboarding_invoice_title_step1,
            description: language.onboarding_invoice_description_step1,
            placement: "right",
            target: "#invoices_picker",
            disableBeacon: true,
            steps: 4
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 126, width: 300 }}>
                    <ThemedImage imageName={'step2-invoices.png'}/>
                </div>,
            title: language.onboarding_invoice_title_step2,
            description: language.onboarding_invoice_description_step2,
            placement: "left-start",
            target: "#invoices_download",
            disableBeacon: true,
            steps: 4
        },        
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 126, width: 300 }}>
                    <ThemedImage imageName={'step4-invoices.png'}/>
                </div>,
            title: language.onboarding_invoice_title_step4,
            description: language.onboarding_invoice_description_step4,
            placement: "top-start",
            target: "#invoices_payment",
            disableBeacon: true,
            steps: 4
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 126, width: 300 }}>
                    <ThemedImage imageName={'step3-invoices.png'}/>
                </div>,
            title: language.onboarding_invoice_title_step3,
            description: language.onboarding_invoice_description_step3,
            placement: "center",
            target: "#invoices_detail",
            disableBeacon: true,
            steps: 4
        },
    ])
};