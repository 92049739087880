import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as paysActions from '../../../store/actions/Pays';

// import cardTag from '../../../imgs/card-tag.svg';

import Input from '../../shared/utilities/Input';
import Select from '../../shared/utilities/Select';
import { themes } from '../../../utils/enums';

const isValidateString = val => {
    return /^(?=.*[a-zA-Zñíóáéú])[ñíóáéú a-zA-Z]+$/.test(val);
}

const isValidUser = data => {

    if (data.phone === null || data.phone === "") {
        return false;
    }

    if (data.address === null || data.address === "") {
        return false;
    }

    if (data.id_document === null || data.id_document === "") {
        return false;
    }

    if (data.card_name === null || data.card_name === "" || !isValidateString(data.card_name)) {
        return false;
    }

    if (data.card_number === null || data.card_number === "") {
        return false;
    }

    if (data.card_expiration_month === null || data.card_expiration_month === "") {
        return false;
    }

    if (data.card_expiration_year === null || data.card_expiration_year === "") {
        return false;
    }

    if (data.card_cvv === null || data.card_cvv === "") {
        return false;
    }

    return true;

}

const separarTexto = (texto) => {
    const expresionRegular = new RegExp('.{1,4}', 'g');
    const grupos = texto.match(expresionRegular);
    return grupos ? grupos.join(' ') : '';
}

let fees = Array.from({ length: 36 }, (e, i) => { return { value: i + 1, label: i + 1 } });

const CardPayment = (props) => {

    let theme = props.theme;
    let account = props.account;
    let infoPayment = props.infoPayment;

    const setEnable = props.setEnable;
    const setInfoPayment = props.setInfoPayment;

    const initInfo = {
        id_user: props.user.idUser,
        id_external_user: 0,
        id_account: account?.idAccount,
        name: props.user.name,
        lastname: props.user.lastname,
        email: props.user.email,
        phone: props.user.phone,
        address: props.user.address,
        id_document: "",
        id_bills: [],
        card_name: "",
        card_number: "",
        card_expiration_year: "",
        card_expiration_month: "",
        card_cvv: "",
        payments: 1,
        neugets: 0,
        commission: 0.007,
        total_payment: props.totalPayment,
    }

    const [defaultInfo, setDefaultInfo] = useState(initInfo);

    useEffect(() => {
        if (defaultInfo != null) {
            setInfoPayment(defaultInfo)
            setDefaultInfo(null)
        }
    }, [defaultInfo, setInfoPayment]);

    useEffect(() => setEnable(isValidUser(infoPayment)), [setEnable, infoPayment]);

    return (
        <div className='column-between align-items-start mt-4 px-3 pb-3 w-100'>
            <div className='column-start gap-3'>
                <div className='row-center w-100'>
                    <div className={`column-between min-width-320 max-width-320 min-height-150 bg-gray-transparent-gradient-diagonal p-3 rounded-16 border-1 border-color-white ${theme}`}>
                        <div className='row-between align-item-center w-100'>
                            <div className={`paragraph-medium-semibold content-default-primary ${theme}`}>{props.language.invoices_card_payment_bank}</div>
                            <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path 
                                    d="M15.143 1.28571C17.0237 4.54326 18.0139 8.23849 18.0139 12C18.0139 15.7615 17.0237 19.4567 15.143 22.7143M10.4287 3.64285C11.8957 6.18374 12.668 9.06602 12.668 12C12.668 14.934 11.8957 17.8162 10.4287 20.3571M5.92871 5.80713C6.98945 7.66394 7.54789 9.77022 7.54789 11.9143C7.54789 14.0583 6.98945 16.1646 5.92871 18.0214M1.42871 8.14285C2.19318 9.29983 2.59847 10.6362 2.59847 12C2.59847 13.3638 2.19318 14.7002 1.42871 15.8571" 
                                    stroke={theme === themes.LIGHT ? '#000000' : '#ffffff'} 
                                    stroke-width="2.57143" 
                                    stroke-linecap="round"
                                />
                            </svg>
                        </div>
                        <div className='row-between align-items-end w-100'>
                            <div className='col-8 column-start align-items-start overflow-hidden'>
                                <div className='col-12 row-start align-items-center pb-1'>
                                    <div className={`col-8 paragraph-XSmall-regular content-default-primary me-3 ${theme}`}>{infoPayment.card_name === '' ? `${props.language.invoices_card_payment_cardholder}` : `${infoPayment.card_name}`.toUpperCase()}</div>
                                    <div className={`col-4 paragraph-XSmall-regular content-default-primary ${theme}`}>{infoPayment.card_expiration_month === '' ? 'MM' : infoPayment.card_expiration_month}/{infoPayment.card_expiration_year === '' ? 'YY' : infoPayment.card_expiration_year}</div>
                                </div>
                                <div className={`paragraph-medium-regular content-default-primary ${theme}`}>{separarTexto(infoPayment.card_number === '' ? 'XXXXXXXXXXXXXXXX' : `${infoPayment.card_number}`)}</div>
                            </div>
                            <div className={`col-2 row-center align-items-center px-3 py-2 surface-on-surface-hover rounded-4 ${theme}`}>
                                <i className={`fa-duotone fa-credit-card content-default-primary txt-12 pe-pointer ${theme}`}></i>
                            </div>
                        </div>
                    </div>
                </div>
                <Input
                    value={infoPayment.card_name ?? ""}
                    label={props.language.invoices_card_payment_cardholder_name}
                    type={'text'}
                    onChange={({ target }) => {
                        setInfoPayment({ ...infoPayment, card_name: target.value })
                    }}
                    theme={theme}
                    help={props.language.invoices_card_payment_equal_cardholder_name}
                    required
                />
                <div className='row-between align-items-center gap-2'>
                    <Input
                        label={props.language.invoices_card_payment_cardholder_document}
                        value={infoPayment.id_document ?? ""}
                        type={'text'}
                        onChange={({ target }) => {
                            setInfoPayment({ ...infoPayment, id_document: target.value })
                        }}
                        theme={theme}
                        required
                    />
                    <Input
                        label={props.language.invoices_card_payment_cardholder_address}
                        value={infoPayment.address ?? ""}
                        type={'text'}
                        onChange={({ target }) => {
                            setInfoPayment({ ...infoPayment, address: target.value })
                        }}
                        theme={theme}
                        required
                    />
                </div>
                <Input
                    label={props.language.invoices_card_payment_number}
                    value={infoPayment.card_number ?? ""}
                    type={'number'}
                    onChange={({ target }) => {
                        target.value.length <= 16 && setInfoPayment({ ...infoPayment, card_number: target.value })
                    }}
                    theme={theme}
                    required
                />
                <div className='row-start align-items-center my-2'>
                    <div className="col-6 pe-1 text-nowrap">
                        <Input
                            label='MM'
                            value={infoPayment.card_expiration_month ?? ""}
                            type={'number'}
                            onChange={({ target }) => {
                                target.value < 12 && setInfoPayment({ ...infoPayment, card_expiration_month: target.value })
                            }}
                            theme={theme}
                            required
                        />
                    </div>
                    <div className="col-6 ps-1 text-nowrap">
                        <Input
                            label='AA'
                            value={infoPayment.card_expiration_year ?? ""}
                            type={'number'}
                            onChange={({ target }) => {
                                target.value.length <= 2 && setInfoPayment({ ...infoPayment, card_expiration_year: target.value })
                            }}
                            theme={theme}
                            required
                        />
                    </div>
                </div>
                <Input
                    label='CVC/CVV'
                    value={infoPayment.card_cvv ?? ""}
                    type={'number'}
                    onChange={({ target }) => {
                        target.value.length <= 4 && setInfoPayment({ ...infoPayment, card_cvv: target.value })
                    }}
                    theme={theme}
                    required
                />
                <div className='col-12 my-2'>
                    <Select
                        label={props.language.invoices_card_payment_instalments}
                        background='surface-bg-surface'
                        options={fees}
                        value={infoPayment.payments}
                        onChange={(value) => {
                            setInfoPayment({ ...infoPayment, payments: value });
                        }}
                        theme={theme}
                        width={"350px"}
                        required
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    infoPayment: state.pays.infoPayment,
    account: state.accounts.account,
    user: state.users.user,
    language: state.shared.language,
    setEnable: ownProps.setEnable,
    totalPayment: ownProps.totalPayment,
    theme: state.theme.theme,
})

const mapDispatchToProps = dispatch => ({
    setInfoPayment: (payload) => dispatch(paysActions.setInfoPayment(payload)),
    setCardPaymentAsync: () => dispatch(paysActions.setCardPaymentAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(CardPayment)