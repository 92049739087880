import React from "react";
import { connect } from "react-redux";

import * as sharedActions from '../../../store/actions/Shared';

import StepProgressBar from "./StepProgressBar";

import { track } from "@amplitude/analytics-browser";

const Tooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    isLastStep,
    skipProps,
    language,
    theme,
    setShowDrawerOnboarding    
}) => {
    const { onClick: backClickHandler, ...otherBackProps } = backProps;
    const { onClick: nextClickHandler, ...otherNextProps } = primaryProps;
    const { onClick: closeClickHandler, ...otherCloseProps } = closeProps;

    const handleBackClick = (event) => {
        track('Onboarding back clicked');

        if (backClickHandler) {
            backClickHandler(event);
        }
    };

    const handleNextClick = (event) => {

        if (index === 0) track('Onboarding begin clicked')
        else track('Onboarding next clicked')

        if (nextClickHandler) {
            nextClickHandler(event);
        }
    };

    const handleCloseClick = (event) => {

        setShowDrawerOnboarding(true);

        if (closeClickHandler) {
            closeClickHandler(event);
        }
    };    

    const tourViewed = window.localStorage.getItem("main-tutorial");
    
    return (
        <div className={`column-between rounded-16 p-3 surface-bg-on-surface ${theme}`} style={{ width: step?.width ? step.width : 362, height: 'auto' }} {...tooltipProps}>
            <div className="column-start w-100">
                {(!step.walktrough && step.steps > 1) ? <StepProgressBar status={index} steps={step.steps} theme={theme} /> : null}
                <div className={`row-between align-items-start w-100 mt-${step.walktrough ? 0 : 3}`}>
                    <div className="column-start" style={{ width: '100%' }}>
                        <p className={`paragraph-XSmall-semibold content-default-primary txt-wrap-pretty ${theme}`}>{step.title}</p>
                        <p className={`paragraph-XSmall-regular content-default-primary txt-wrap-pretty mt-2 ${theme}`}>{step.description}</p>
                    </div>
                </div>
                <div className="w-100">{step.content}</div>
            </div>
            <div className={`row-${!step.walktrough && isLastStep ? 'end' : 'between'} align-items-center w-100 mt-4`}>
                {!isLastStep && <div className={`label-small-special-underline content-default-secondary pe-pointer ${theme}`} {...skipProps}>{language.tutorial_skip_button}</div>}
                {step.walktrough && <div onClick={(event) => handleCloseClick(event)} className={`label-small-special-underline content-default-secondary pe-pointer ${theme}`} {...otherCloseProps}>{language.onboarding_modal_label_redirection}</div>}
                {!step?.hideFooter ?
                    <div className="row-start align-items-center">
                        {index > 0 && !step?.hideBackButton && (
                            <div onClick={(event) => handleBackClick(event)} className={`row-center align-items-center rounded-pill surface-bg-on-surface-alpha border-fill-medium me-2 px-${index === 0 ? 4 : 2} ${theme}`} style={{height: 32, minWidth: 32}} {...otherBackProps}>
                                <i className={`fal fa-chevron-left txt-8 content-default-secondary ${theme}`} id="back" />
                            </div>
                        )}
                        {continuous && !isLastStep && (
                            <div onClick={(event) => handleNextClick(event)} className={`row-center align-items-center rounded-pill surface-bg-on-surface-alpha border-fill-medium px-${index === 0 ? 4 : 2} ${theme}`} style={{height: 32, minWidth: 32}} {...otherNextProps}>
                                {index === 0 && <p className={`label-small-regular content-default-primary me-2 pe-pointer ${theme}`}>{language.tutorial_start_button}</p>}
                                <i className={`fal fa-chevron-right txt-8 content-default-secondary ${theme}`} id="next" />
                            </div>
                        )}                        
                        {isLastStep && (
                            <div onClick={(event) => handleNextClick(event)} className={`row-center align-items-center surface-bg-on-surface-alpha border-fill-medium rounded-pill ${tourViewed !== 'show' ? 'px-4' : 'px-2'} ${theme}`} style={{height: 32, minWidth: 32}} {...otherNextProps}>
                                {step.walktrough ? 
                                    <p className={`label-small-regular content-default-primary pe-pointer ${theme}`}>{language.tutorial_got_it}</p> : 
                                    <div className="row-center align-items-center">
                                        {tourViewed !== 'show' ? <p className={`label-small-regular content-default-primary pe-pointer me-2 ${theme}`}>{language.onboarding_modal_finally}</p> : null}
                                        <i className={`fal fa-chevron-right txt-8 content-default-secondary ${theme}`} id="next" />
                                    </div>
                                }
                            </div>
                        )}
                    </div> :
                    null}
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    language: state.shared.language,
    theme: state.theme.theme   
});

const mapDispatchToProps = dispatch => ({    
    setShowDrawerOnboarding: (payload) => dispatch(sharedActions.setShowDrawerOnboarding(payload))    
})

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip);