import React, { useState } from "react"
import { connect } from "react-redux"

import * as sharedActions from "../../store/actions/Shared"
import * as alertsActions from "../../store/actions/Alerts"
import * as usersActions from "../../store/actions/Users"

import { Drawer } from "rsuite"

import { useHistory } from "react-router-dom"
import BackButton from "../shared/utilities/BackButton"


export const tutorialListInfoGlobal = (props, history, chatBot = false) => {
  const language = props?.language;
  return [    
    {
      id: 1,
      icon: "file-invoice",
      title: language.onboarding_invoice_title,
      description: language.onboarding_payment_description,
      tutorialFunction: () => {
        history.push("/invoices")
        props.setStartOnboarding({ key: "invoice", value: true })
        if (!chatBot) props.setShowAccount(false)
        props.setShowDrawerOnboarding(false)
      },
    },
    {
      id: 2,
      icon: "circle-plus",
      title: language.onboarding_dashboard_title,
      description: language.onboarding_dashboard_description,
      tutorialFunction: () => {
        const currentPath = history.location.pathname

        if (currentPath !== "/home") {

          history.push("/home")

          setTimeout(() => {
            props.setStartOnboarding({ key: "dashboard", value: true })
          }, 5000);
        } else {
          props.setStartOnboarding({ key: "dashboard", value: true })
        }
        if (!chatBot) props.setShowAccount(false)
        props.setShowDrawerOnboarding(false)
      },
    },
    // {
    //   id: 3,
    //   icon: "user",
    //   title: language.onboarding_new_user_title,
    //   description: language.onboarding_new_user_description,
    //   tutorialFunction: () => {        
    //     setTimeout(() => {
    //       props.setStartOnboarding({ key: "new_user", value: true })
    //     }, 500);        
    //     props.setShowCreateUserModal(true)
    //     props.setShowDrawerOnboarding(false)
    //   },
    // },
    {
      id: 4,
      icon: "sensor-on",
      title: language.onboarding_new_alert_title,
      description: language.onboarding_new_alert_description,
      tutorialFunction: () => {    
        props.setShowDrawerAlerts(true)  
        setTimeout(() => {
          props.setStartOnboarding({ key: "new_alert", value: true }) 
        }, 500);  
        // props.setStartOnboarding({ key: "new_alert", value: true })        
        props.setShowDrawerOnboarding(false)
      },
    },
    {
      id: 5,
      icon: "circle-check",
      title: language.onboarding_service_picker_title,
      description: language.onboarding_service_picker_description,
      tutorialFunction: () => {
        history.push("/home/detail")
        props.setStartOnboarding({ key: "service_detail", value: true })
        if (!chatBot) props.setShowAccount(false)
        props.setShowDrawerOnboarding(false)
      },
    },    
    {
      id: 7,
      icon: "chart-simple",
      title: language.onboarding_graph_type_picker_title,
      description: language.onboarding_graph_type_picker_description,
      tutorialFunction: () => {
        history.push("/home/detail")
        props.setStartOnboarding({ key: "graph_type_picker", value: true })
        if (!chatBot) props.setShowAccount(false)
        props.setShowDrawerOnboarding(false)
      },
    },
    {
      id: 8,
      icon: "calendar",
      title: language.onboarding_graph_period_picker_title,
      description: language.onboarding_graph_period_picker_description,
      tutorialFunction: () => {
        history.push("/home/detail")
        props.setStartOnboarding({ key: "graph_period_picker", value: true })
        if (!chatBot) props.setShowAccount(false)
        props.setShowDrawerOnboarding(false)
      },
    },
    {
      id: 9,
      icon: "circle-arrow-down",
      title: language.onboarding_graph_export_data_title,
      description: language.onboarding_graph_export_data_description,
      tutorialFunction: () => {
        history.push("/home/detail")
        props.setStartOnboarding({ key: "graph_export_data", value: true })
        if (!chatBot) props.setShowAccount(false)
        props.setShowDrawerOnboarding(false)
      },
    },
    {
      id: 10,
      icon: "calendar-range",
      title: language.onboarding_graph_calendar_picker_title,
      description: language.onboarding_graph_calendar_picker_description,
      tutorialFunction: () => {
        history.push("/home/detail")
        props.setStartOnboarding({ key: "graph_calendar_picker", value: true })
        if (!chatBot) props.setShowAccount(false)
        props.setShowDrawerOnboarding(false)
      },
    },
  ]};


const OnboardingSections = props => {
  const showBlackScreenDrawer = props.showBlackScreenDrawer
  const showDrawerOnboarding = props.showDrawerOnboarding
  const language = props.language
  let theme = props.theme;
  const history = useHistory();
  const tutorialListInfo = tutorialListInfoGlobal(props, history);

  const [showItems, setShowItems] = useState(Math.floor(tutorialListInfo.length / 2))

  const TutorialContainer = ({ icon, title, description, tutorialFunction }) => {
    return (
      <div id="tuto-item" className={`w-100 row-between align-items-center p-2 rounded-16 pe-pointer surface-bg-on-surface border-fill-medium ${theme}`} onClick={tutorialFunction}>
        <div className={`col-2 rounded-8 row-center align-items-center surface-bg-surface ${theme}`} style={{height: 54}}>
          <i className={`fa-solid fa-${icon} txt-14 content-default-secondary ${theme}`}></i>
        </div>
        <div className="flex-grow-1 column-start justify-content-around ms-3">
          <p className={`paragraph-XSmall-semibold content-default-primary pe-pointer txt-wrap-pretty ${theme}`}>{title}</p>
          <p className={`paragraph-XSmall-regular content-default-secondary mt-1 ${theme}`}>{description}</p>
        </div>
        <div className="p-1">
          <i className={`fa-light fa-circle-chevron-right txt-10 content-default-secondary ${theme}`}></i>
        </div>
      </div>
    )
  }

  return (
    <Drawer
      show={showDrawerOnboarding}
      size={"xs"}
      placement={"right"}
      backdropClassName={`pe-pointer ${theme}`}
      onHide={() => {
        props.setShowDrawerOnboarding(false)
      }}>
      <div className={`position-relative vh-100 w-100 overflow-y-scroll surface-bg-surface ${theme}`}>
        <div className='column-start align-items-center py-3 px-4'>          
          <div className="row-between w-100 my-3 align-items-center">
            <BackButton theme={theme} onClick={() => props.setShowDrawerOnboarding(false)}/>
            <p className={`heading-h6-bold content-default-primary ${theme}`}>{language.onboarding_drawer_title}</p>
          </div>
          <div>            
            <div className="column-start align-items-center gap-3 mt-3">
              {tutorialListInfo.slice(0, showItems).map(tutorial => {
                return <TutorialContainer key={tutorial.id} icon={tutorial.icon} title={tutorial.title} description={tutorial.description} tutorialFunction={tutorial.tutorialFunction} />
              })}
              <div
                className={`p-2 row-center align-items-center rounded-pill w-100 pe-pointer border-fill-bold button-content-default label-small-regular ${theme}`}
                onClick={() => {
                  if (showItems === tutorialListInfo.length) {
                    setShowItems(Math.floor(tutorialListInfo.length / 2))
                  } else {
                    setShowItems(tutorialListInfo.length)
                  }
                }}>
                {showItems === tutorialListInfo.length ? `${language.onboarding_drawer_hide}` : `${language.onboarding_drawer_show}`}
                <i className={`fa-light fa-chevron-down txt-8 button-content-default transition-transform ms-2 ${showItems === tutorialListInfo.length ? "rotate-180" : ""} ${theme}`}></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

const mapStateToProps = state => ({
  language: state.shared.language,
  showBlackScreenDrawer: state.shared.showBlackScreenDrawer,
  showDrawerOnboarding: state.shared.showDrawerOnboarding,
  theme: state.theme.theme,
})

const mapDispatchToProps = dispatch => ({
  setShowDrawerOnboarding: payload => dispatch(sharedActions.setShowDrawerOnboarding(payload)),
  setStartOnboarding: payload => dispatch(sharedActions.setStartOnboarding(payload)),
  setShowDrawerAlerts: payload => dispatch(alertsActions.setShowDrawerAlerts(payload)),
  setShowCreateUserModal: payload => dispatch(usersActions.setShowCreateUserModal(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingSections)
