import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Drawer, Animation } from 'rsuite';

import IntervalTime from './IntervalTime';
import Input from '../shared/utilities/Input';
import CheckPicker from '../shared/utilities/CheckPicker';

import * as alertsActions from '../../store/actions/Alerts';
import * as sharedActions from '../../store/actions/Shared';

import _ from 'lodash';
import { track } from "@amplitude/analytics-browser";
import BackButton from '../shared/utilities/BackButton';

const conditions = language => [
    {
        id_condition: 0,
        label: language.alerts_condition_greater_than
    },
    {
        id_condition: 2,
        label: language.alerts_condition_same_as
    },
    {
        id_condition: 1,
        label: language.alerts_condition_less_than
    },
];

const channels = language => [
    {
        id_channel: 1,
        label: language.alerts_channel_email,
        eventProperty: 'Mail_Alert'
    },
    {
        id_channel: 3,
        label: language.alerts_channel_text_message,
        eventProperty: 'Msm_Alert'
    },
    {
        id_channel: 2,
        label: language.alerts_channel_notification,
        eventProperty: 'Notification_Alert'
    },
];

const days = language => [
    {
        id_day: 1,
        label: language.alerts_select_days_monday,
        eventProperty: 'Monday_Interval'
    },
    {
        id_day: 2,
        label: language.alerts_select_days_tuesday,
        eventProperty: 'Tuesday_Interval'
    },
    {
        id_day: 3,
        label: language.alerts_select_days_wednesday,
        eventProperty: 'Wednesday_Interval'
    },
    {
        id_day: 4,
        label: language.alerts_select_days_thursday,
        eventProperty: 'Thursday_Interval'
    },
    {
        id_day: 5,
        label: language.alerts_select_days_friday,
        eventProperty: 'Friday_Interval'
    },
    {
        id_day: 6,
        label: language.alerts_select_days_saturday,
        eventProperty: 'Saturday_Interval'
    },
    {
        id_day: 7,
        label: language.alerts_select_days_sunday,
        eventProperty: 'Sunday_Interval'
    },
];

const intervals = language => [
    {
        id_interval: 4,
        label: language.alerts_select_interval_secondBySecond,
        eventProperty: 'SecondBySecond_Interval'
    },
    {
        id_interval: 0,
        label: language.alerts_select_interval_hourly,
        eventProperty: 'Hourly_Interval'
    },
    {
        id_interval: 1,
        label: language.alerts_select_interval_daily,
        eventProperty: 'Daily_Interval'
    },
    {
        id_interval: 2,
        label: language.alerts_select_interval_weekly,
        eventProperty: 'Weekly_Interval'
    },
    {
        id_interval: 3,
        label: language.alerts_select_interval_monthly,
        eventProperty: 'Monthly_Interval'
    }
];

const combinedArray = (array1, array2) => {
    const newArray = array1.map(item1 => {
        const match = array2.find(item2 => 
            item2.id_service === item1?.idService && item2?.id_infrastructure === 2
        );
        if (match) {
            return {
            ...item1,
            id_device: match?.id_device
            };
        }
        return item1;
    });
    return newArray;
};

const Alerts = (props) => {

    const initAlert = {
        idAlert: null,
        name: null,
        serviceIds: [],
        graphIds: [],
        condition: [],
        value: null,
        channelIds: [],
        intervalTime: null,
    }

    const [alert, setAlert] = useState(initAlert);
    const [activeService, setActiveService] = useState(false);
    const [activeVariable, setActiveVariable] = useState(false);
    const [activeRule, setActiveRule] = useState(false);
    const [activeValue, setActiveValue] = useState(false);
    const [activeChannel, setActiveChannel] = useState(false);
    const [showAlertPeriod, setShowPeriodAlert] = useState(false);
    const [alertProperty, setAlertProperty] = useState({ Variable_ID: '', Alert_Type: '', Project_Quantity: 0, Interval_ID: '', Interval_Day: '' })
    const [searchTerm, setSearchTerm] = useState('');

    let showNewAlert = props.showNewAlert;
    let newAlert = props.newAlert;
    let graphs = props.graphs;
    let language = props.language;
    let theme = props.theme;

    const setControlledOnboarding = props.setControlledOnboarding;

    useEffect(() => {
        if (showNewAlert && newAlert) {
            setControlledOnboarding({ key: 'new_alert', value: true })
        } else {
            setControlledOnboarding({ key: 'new_alert', value: false })
        }
    }, [showNewAlert, setControlledOnboarding, newAlert])

    useEffect(() => {
        if (props.alertSelected) {

            const fromHour = parseInt(props.alertSelected?.start_hour?.split(':')[0]);
            const fromHourFormated = fromHour > 12 ? fromHour - 12 : fromHour;
            const toHour = parseInt(props.alertSelected?.end_hour?.split(':')[0]);
            const toHourFormated = toHour > 12 ? toHour - 12 : toHour;

            const alertTime = {
                dayIds: props.alertSelected?.active_week_days,
                fromHour: fromHourFormated < 10 ? '0' + String(fromHourFormated) : String(fromHourFormated),
                fromMinutes: props.alertSelected?.start_hour?.split(':')[1],
                fromPartOfDay: fromHour > 12 ? 'pm' : 'am',
                toHour: toHourFormated < 10 ? '0' + String(toHourFormated) : String(toHourFormated),
                toMinutes: props.alertSelected?.end_hour?.split(':')[1],
                toPartOfDay: toHour > 12 ? 'pm' : 'am',
                intervalIds: [props.alertSelected?.id_frequency],
                dates: []
            }

            setAlert({
                ...alert, idAlert: props.alertSelected?.id_alert,
                name: props.alertSelected?.alert_name,
                serviceIds: props.alertSelected?.services?.map(item => item?.id),
                graphIds: [props.alertSelected?.id_graph],
                condition: [props.alertSelected?.id_alert_type],
                value: props.alertSelected?.value,
                channelIds: props.alertSelected?.channels.map(item => item?.id),
                intervalTime: alertTime
            })
        }
        // eslint-disable-next-line
    }, [props.alertSelected]);


    const formatLabel = (array1, array2, keyName, labelName) => {

        let array3 = array1.map(x => array2.filter(y => y[keyName] === x))
            .flat()

        return array3.map((s, index) => {
            if (index === array3.length - 1) {
                return `${s[labelName]}. `;
            } else {
                return `${s[labelName]}, `;
            }

        }).join("");
    }

    const formatAmplitudeEvent = (array1, array2, keyName, labelName) => {

        let array3 = array1.map(x => array2.filter(y => y[keyName] === x))
            .flat()

        return array3.map((s, index) => {
            return s[labelName]
        });
    }

    const validateSecondBySecond = () => {
        const servicesPlus = combinedArray(props.totalServices, props.devices)?.filter(item => 'id_device' in item);
        const servicesSelectedPlus = servicesPlus?.filter(item => alert?.serviceIds?.includes(item?.idService));
        const idsGraphsPlus = [29,30,7,8];
        return servicesSelectedPlus.length && idsGraphsPlus?.filter(item => alert?.graphIds?.includes(item))?.length
    }

    const validatePlus = graphs => {
        const servicesPlus = combinedArray(props.totalServices, props.devices)?.filter(item => 'id_device' in item);
        const servicesSelectedPlus = servicesPlus?.filter(item => alert?.serviceIds?.includes(item?.idService))
        const idsGraphsPlus = [29,30,7,8];
        if (servicesSelectedPlus.length){
            let plusGraphs = graphs?.filter(item => idsGraphsPlus?.includes(item?.id_graph));
            plusGraphs = plusGraphs?.map(item => { return {...item, plus: true}});
            const energyGraphs = graphs?.filter(item => item?.id_graph <= 8);
            return [...plusGraphs, ...energyGraphs]
        }
        else{
            return graphs
        }
    }

    const translatedGraphs = props.graphs && props.graphs.options.map((_graph) => {
        const labelTranslation = props.language[`energy_graph_label_${_graph.value.toLowerCase()}`]

        return {
            ..._graph,
            labelTranslation: labelTranslation,
        }
    })

    const formatGraphLabel = () => {
        if (!translatedGraphs) return

        const graphSelected = translatedGraphs.filter((graph) => graph.id_graph === alert.graphIds[0])
        return graphSelected[0]?.labelTranslation
    }

    useEffect(() => {
        if (alert.serviceIds.length) { setAlertProperty(prevState => ({ ...prevState, Project_Quantity: alert.serviceIds.length })) }
        if (alert.graphIds.length) { setAlertProperty(prevState => ({ ...prevState, Variable_ID: graphs.options.filter(g => g.id_graph === alert.graphIds[0])[0]?.value })) }
        if (alert.channelIds.length) { setAlertProperty(prevState => ({ ...prevState, Alert_Type: formatAmplitudeEvent(alert.channelIds, channels(language), 'id_channel', 'eventProperty') })) }
        if (alert.intervalTime?.intervalIds.length) { setAlertProperty(prevState => ({ ...prevState, Interval_ID: formatAmplitudeEvent(alert.intervalTime.intervalIds, intervals(language), 'id_interval', 'eventProperty') })) }
        if (alert.intervalTime?.dayIds.length) { setAlertProperty(prevState => ({ ...prevState, Interval_Day: formatAmplitudeEvent(alert.intervalTime.dayIds, days(language), 'id_day', 'eventProperty') })) }
    }, [alert, setAlertProperty, graphs, language])


    const handleClose = () => {
        props.setShowNewAlert(false)
        props.setAlertSelected(null)
        props.setEditAlert(false)
        setAlert(initAlert)
    }

    const filteredServices = combinedArray(
        props.totalServices.filter(service =>
            service.name.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        props.devices
    );

    const groupedServices = _.groupBy(filteredServices, (service) => service.account?.idAccount || 'Sin cuenta');

    const toggleSelectAllServicesByAccount = (services) => {
        const accountServiceIds = services.map(service => service.idService);
        const areAllSelected = accountServiceIds.every(id => alert.serviceIds.includes(id));

        setAlert({
            ...alert,
            serviceIds: areAllSelected
                ? alert.serviceIds.filter(id => !accountServiceIds.includes(id)) 
                : [...alert.serviceIds, ...accountServiceIds], 
        });
    };

    return (
        <Drawer
            show={props.showNewAlert}
            size={'xs'}
            placement={'right'}
            backdropClassName={`pe-pointer ${theme}`}
            onHide={() => handleClose()}>
            <div id='new_alert' className={`position-relative vh-100 w-100 overflow-y-scroll surface-bg-surface ${theme}`}>
                <div className='column-start align-items-center py-3 px-4'>
                    <div className='row-between w-100 align-items-center my-3'>
                        <BackButton theme={theme} onClick={() => handleClose()}></BackButton>
                        <div className='row-center align-items-center gap-2'>
                            <p className={`heading-h6-bold content-default-primary ${theme}`}>{props.alertSelected && props.editAlert ? props.language.alerts_label_title_edit_alert : props.language.alerts_label_title_new_alert}</p>
                        </div>
                    </div>
                    <div className={`row-center w-100 p-2 rounded-8 bg-alpha-white-8 border-fill-medium ${theme}`}>
                        <p className={`text-wrap label-small-medium content-default-primary ${theme}`}>{props.language.alerts_label_body_new_alert}</p>
                    </div>
                    <div className='row-center w-100 my-3'>
                        <Input label={'Nombre de la alerta'} placeholder='Nombre' type={'text'} value={alert.name ? alert.name : ''}
                            onChange={({ target }) => {
                                setAlert({ ...alert, name: target.value })
                            }}
                        />
                    </div>
                    <div className={`column-center py-3 w-100 align-items-center gap-3 border-fill-medium-top border-fill-medium-bottom ${theme}`}>
                    <div className={`column-start align-items-center w-100 p-3 rounded-16 surface-bg-on-surface ${alert.serviceIds.length ? 'border-color-green' : 'border-fill-medium'} ${theme}`} onClick={() => setActiveService(!activeService)}>
                        <div className="row-between align-items-center w-100 pe-pointer">
                          <div className="row-start align-items-center" style={{width: '80%'}}>
                            <i className={`${alert.serviceIds.length ? 'fa-solid content-color-green' : 'fa-regular content-default-secondary'} fa-circle-bolt txt-10 ${theme}`}></i>
                            <div className='w-100 column-start aling-itmes-center ms-3'>
                              <p className={`label-small-medium pe-pointer content-default-primary ${theme}`}>{alert.serviceIds.length ? language.alerts_new_alert_service_item : language.alerts_new_alert_service_label}</p>
                              <p className={`label-XSmall-regular content-default-secondary mt-0 txt-truncate ${theme}`}>{alert.serviceIds.length ? formatLabel(alert.serviceIds, props.totalServices, 'idService', 'name') : language.alerts_new_alert_service_example}</p>
                            </div>
                          </div>
                          <i style={{width: '20%'}} className={`${theme} text-end txt-10 pe-pointer ${activeService ? 'fa-regular fa-chevron-up content-default-secondary' : !alert.serviceIds.length ? 'fa-regular fa-chevron-right content-default-secondary' : 'fa-solid fa-circle-check content-color-green'}`}></i>
                        </div>
                        <Animation.Collapse in={activeService} dimension={'height'}>
                          <div className={`column-start w-100 rounded-8 mt-3 surface-bg-surface ${theme}`}>
                            <div className={`column-center w-100 pt-3 px-3`}>
                                <div className={`column-center w-100 border-fill-medium-bottom pb-2 ${theme}`}>
                                    <div className={`border-fill-regular sticky-top py-2 px-3 rounded-16 surface-bg-surface ${theme}`}>
                                        <div className="row-start align-items-center">
                                            <i className={`fal fa-search txt-10 content-default-secondary me-2 ${theme}`} />
                                            <input
                                            type="text"
                                            onClick={(e) => e.stopPropagation()}
                                            className={`border-0 w-100 surface-bg-surface label-medium-medium content-default-secondary ${theme}`}
                                            placeholder={props.language.alerts_new_alert_search_proyects}
                                            value={searchTerm}
                                            onChange={({ target }) => setSearchTerm(target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100 colum-start overflow-y-scroll rounded-8 mt-3' style={{maxHeight: '23rem'}}>
                            {Object.entries(groupedServices).map(([accountId, services]) => (
                                <div key={accountId} className='mb-2'>
                                    {/* Encabezado de la cuenta */}
                                    <div className={`row-between align-items-center w-100 px-3`}>
                                        <p className={`label-small-regular content-default-secondary txt-truncate ${theme}`} style={{maxWidth: '7rem'}}>
                                            {services[0]?.account?.name || language.alerts_new_alert_no_account}
                                        </p>
                                        <p
                                            className={`label-small-regular content-color-green mt-0 pe-pointer text-nowrap  ${theme}`}
                                            onClick={() => toggleSelectAllServicesByAccount(services)}
                                        >
                                            {services.every(service => alert.serviceIds.includes(service.idService))
                                                ? language.services_picker_deselect_all
                                                : language.services_picker_select_all}
                                        </p>
                                    </div>
                                    {/* Listado de servicios */}
                                    <CheckPicker
                                        keyName='idService'
                                        labelName='name'
                                        options={services}
                                        selectedData={alert.serviceIds}
                                        onChange={(value) => setAlert({ ...alert, serviceIds: value })}
                                        theme={theme}
                                        padding='py-3 ps-3 pe-1'
                                    />
                                </div>
                            ))}
                            </div>
                          </div>
                       </Animation.Collapse>
                    </div>
                    <div className={`column-start align-items-center w-100 p-3 rounded-16 surface-bg-on-surface ${alert.graphIds.length ? 'border-color-green' : 'border-fill-medium'} ${theme}`} onClick={() => setActiveVariable(!activeVariable)}>
                       <div className="row-between align-items-center w-100 pe-pointer">
                          <div className="row-start align-items-center">
                            <i className={`${alert.graphIds.length ? 'fa-solid content-color-green' : 'fa-regular content-default-secondary'} fa-sensor txt-10 ${theme}`}></i>
                            <div className='column-start aling-itmes-center ms-3'>
                              <p className={`label-small-medium pe-pointer content-default-primary ${theme}`}>{alert.graphIds.length ? language.alerts_new_alert_variable_item : language.alerts_new_alert_variable_label}</p>
                              <p className={`label-XSmall-regular content-default-secondary mt-0 ${theme}`}>{alert.graphIds.length ? formatGraphLabel() : language.alerts_new_alert_variable_example}</p>
                            </div>
                          </div>
                          <i className={`${theme} txt-10 pe-pointer ${activeVariable ? 'fa-regular fa-chevron-up content-default-secondary' : !alert.graphIds.length ? 'fa-regular fa-chevron-right content-default-secondary' : 'fa-solid fa-circle-check content-color-green'}`}></i>
                        </div>
                        <Animation.Collapse in={activeVariable} dimension={'height'}>
                           <div className={`column-start w-100 rounded-8 mt-3  surface-bg-surface ${theme}`}>
                                <CheckPicker
                                    keyName='id_graph'
                                    labelName='labelTranslation'
                                    options={validatePlus(translatedGraphs)}
                                    selectedData={alert.graphIds}
                                    onChange={(value) => { setAlert({ ...alert, graphIds: [_.last(value)] }) }}
                                    theme={theme}
                                />
                          </div>
                        </Animation.Collapse>
                    </div>
                    <div className={`column-start align-items-center w-100 p-3 rounded-16 surface-bg-on-surface ${alert.condition.length ? 'border-color-green' : 'border-fill-medium'} ${theme}`} onClick={() => setActiveRule(!activeRule)}>
                       <div className="row-between align-items-center w-100 pe-pointer">
                          <div className="row-start align-items-center">
                            <i className={`${alert.condition.length ? 'fa-solid content-color-green' : 'fa-regular content-default-secondary'} fa-power-off txt-10 ${theme}`}></i>
                            <div className='column-start aling-itmes-center ms-3'>
                              <p className={`label-small-medium pe-pointer content-default-primary ${theme}`}>{alert.condition.length ? language.alerts_new_alert_rule_item : language.alerts_new_alert_rule_label}</p>
                              <p className={`label-XSmall-regular content-default-secondary mt-0 ${theme}`}>{alert.condition.length ? formatLabel(alert.condition, conditions(props.language), 'id_condition', 'label') : language.alerts_new_alert_rule_example}</p>
                            </div>
                          </div>
                          <i className={`${theme} txt-10 pe-pointer ${activeRule ? 'fa-regular fa-chevron-up content-default-secondary' : !alert.condition.length ? 'fa-regular fa-chevron-right content-default-secondary' : 'fa-solid fa-circle-check content-color-green'}`}></i>
                        </div>
                        <Animation.Collapse in={activeRule} dimension={'height'}>
                          <div className={`column-start w-100 rounded-8 mt-3 surface-bg-surface ${theme}`}>
                               <CheckPicker
                                    keyName='id_condition'
                                    labelName='label'
                                    options={conditions(props.language)}
                                    selectedData={alert.condition}
                                    onChange={(value) => { setAlert({ ...alert, condition: [_.last(value)] }) }}
                                    theme={theme}
                                />
                           </div>
                        </Animation.Collapse>
                    </div>
                    <div className={`column-start align-items-center w-100 p-3 rounded-16 surface-bg-on-surface ${alert.value? 'border-color-green' : 'border-fill-medium'} ${theme}`} onClick={() => setActiveValue(!activeValue)}>
                       <div className="row-between align-items-center w-100 pe-pointer">
                          <div className="row-start align-items-center">
                            <i className={`${alert.value ? 'fa-solid content-color-green' : 'fa-regular content-default-secondary'} fa-hashtag txt-10 ${theme}`}></i>
                            <div className='column-start aling-itmes-center ms-3'>
                              <p className={`label-small-medium pe-pointer content-default-primary ${theme}`}>{alert.value ? language.alerts_new_alert_value_item : language.alerts_new_alert_value_label}</p>
                              <p className={`label-XSmall-regular content-default-secondary mt-0 ${theme}`}>{alert.value ? `${alert.value} ${alert?.graphIds?.length && props.graphs?.length ? props.graphs.options.filter(x => x?.id_graph === alert?.graphIds[0])[0]?.unit : ""}` : language.alerts_new_alert_value_example}</p>
                            </div>
                          </div>
                          <i className={`${theme} txt-10 pe-pointer ${activeValue ? 'fa-regular fa-chevron-up content-default-secondary' : !alert.value ? 'fa-regular fa-chevron-right content-default-secondary' : 'fa-solid fa-circle-check content-color-green'}`}></i>
                        </div>
                        <Animation.Collapse in={activeValue} dimension={'height'}>
                          <div className='column-start w-100 mt-3'>
                            <Input type={'number'} value={alert.value ? alert.value : ''}
                                onChange={({ target }) => {
                                     setAlert({ ...alert, value: target.value })
                                }}
                                placeholder='000'
                            />
                          </div>
                        </Animation.Collapse>
                    </div>
                    <div className={`column-start align-items-center w-100 p-3 rounded-16 surface-bg-on-surface ${alert.channelIds.length ? 'border-color-green' : 'border-fill-medium'} ${theme}`} onClick={() => setActiveChannel(!activeChannel)}>
                       <div className="row-between align-items-center w-100 pe-pointer">
                          <div className="row-start align-items-center">
                            <i className={`${alert.channelIds.length ? 'fa-solid content-color-green' : 'fa-regular content-default-secondary'} fa-message txt-10 ${theme}`}></i>
                            <div className='column-start aling-itmes-center ms-3'>
                              <p className={`label-small-medium pe-pointer content-default-primary ${theme}`}>{alert.channelIds.length ? language.new_alert_type_title : language.new_alert_select_type}</p>
                              <p className={`label-XSmall-regular content-default-secondary mt-0 ${theme}`}>{alert.channelIds.length ? formatLabel(alert.channelIds, channels(props.language), 'id_channel', 'label') : language.alerts_new_alert_channel_example}</p>
                            </div>
                          </div>
                          <i className={`${theme} txt-10 pe-pointer ${activeChannel ? 'fa-regular fa-chevron-up content-default-secondary' : !alert.channelIds.length ? 'fa-regular fa-chevron-right content-default-secondary' : 'fa-solid fa-circle-check content-color-green'}`}></i>
                        </div>
                        <Animation.Collapse in={activeChannel} dimension={'height'}>
                          <div className={`column-start w-100 rounded-8 mt-3  surface-bg-surface ${theme}`}>
                            <CheckPicker
                                keyName='id_channel'
                                labelName='label'
                                options={channels(props.language)}
                                selectedData={alert.channelIds}
                                onChange={(value) => { setAlert({ ...alert, channelIds: value }) }}
                                theme={theme}
                            />
                          </div>
                        </Animation.Collapse>
                    </div>
                    </div>
                    <div className='w-100 my-3 pb-5'>
                        <div className={`column-start p-3 rounded-16 surface-bg-on-surface pe-pointer ${alert.intervalTime ? 'border-color-green' : 'border-fill-medium'} ${theme}`} onClick={() => setShowPeriodAlert(true)}>
                            <div className='row-between align-items-center w-100'>
                                <div className='column-start alig-items-center'>
                                 <p className={`${alert.intervalTime ? 'content-default-primary' : 'content-default-secondary'} label-medium-semibold ${theme}`}>{alert.intervalTime?.intervalIds.length ? `Intervalo ${formatLabel(alert.intervalTime.intervalIds, intervals(props.language), 'id_interval', 'label')}` : language.alerts_title_interval_time}</p>
                                 <p className={`${alert.intervalTime ? 'content-default-primary' : 'content-default-secondary'} label-XSmall-regular w-100 text-wrap mt-0 ${theme}`}>{alert.intervalTime?.dayIds.length ? `${formatLabel(alert.intervalTime.dayIds, days(props.language), 'id_day', 'label')} ${alert.intervalTime?.fromHour ? alert.intervalTime.fromHour : ''}:${alert.intervalTime?.fromMinutes ? alert.intervalTime.fromMinutes : ''}${alert.intervalTime?.fromPartOfDay ? alert.intervalTime.fromPartOfDay : ''}-${alert.intervalTime?.toHour ? alert.intervalTime.toHour : ''}:${alert.intervalTime?.toMinutes ? alert.intervalTime.toMinutes : ''}${alert.intervalTime?.toPartOfDay ? alert.intervalTime.toPartOfDay : ''}` : language.new_alert_schedule_description}</p>
                                </div>
                                <i className={`fas ${alert.intervalTime ? 'fa-circle-check content-color-green select-detail-check' : 'fa-chevron-right'} txt-10 pe-pointer content-default-secondary ${theme}`}></i>
                            </div>
                        </div>
                    </div>
                </div>
                <IntervalTime
                    onChange={value => setAlert({ ...alert, intervalTime: value })}
                    showAlertPeriod={showAlertPeriod}
                    setShowPeriodAlert={setShowPeriodAlert}
                    intervalTime={alert?.intervalTime}
                />
                <div className={`position-fixed bottom-0 w-100 row-end align-items-center p-3 border-fill-medium-top surface-bg-surface ${theme}`}>
                    <button
                       className={`row-center align-items-center py-2 px-3 rounded-30 button-fill-brand button-content-equal label-small-regular ${theme}`}
                       onClick={async () => {
                                if (props.editAlert) {
                                    await props.editAlertAsync(alert);
                                }
                                else {
                                    await props.createAlertAsync(alert);
                                    track('Alert created', { ...alertProperty, Project_Type: props.totalServices.filter(t => t.idService === alert.serviceIds[0])[0]?.type?.name })
                                }
                                props.setShowNewAlert(false);
                                props.setAlertSelected(null);
                                props.setEditAlert(false);
                                setAlert(initAlert);
                        }}>
                      {props.language.alerts_button_save}
                    </button>
                </div>
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    totalServices: state.services.totalServices,
    graphs: state.energy.graphs[0],
    newAlert: state.shared.startOnboarding.new_alert,
    devices: state.plus.devices,
    theme: state.theme.theme,
});

const mapDispatchToProps = dispatch => ({
    createAlertAsync: (payload) => dispatch(alertsActions.createAlertAsync(payload)),
    editAlertAsync: (payload) => dispatch(alertsActions.editAlertAsync(payload)),
    setControlledOnboarding: (payload) => dispatch(sharedActions.setControlledOnboarding(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);