import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import * as usersActions from '../../../store/actions/Users';

import { useTable, useSortBy, usePagination } from "react-table";

import { Modal, Loader } from 'rsuite';

import Table from "../utilities/Table";
import MultipleSelect from '../utilities/MultipleSelect';

const columns = [
    {
        Header: "",
        id: 'id',
        accessor: "idUser"
    },
    {
        Header: `Nombres`,
        id: 'name',
        accessor: "name"
    },
    {
        Header: 'Apellido',
        id: 'lastname',
        accessor: "lastname"
    },
    {
        Header: 'Teléfono',
        id: 'phone',
        accessor: "phone"
    },
    {
        Header: 'Correo electrónico',
        id: 'email',
        accessor: "email"
    },
    {
        Header: 'Área',
        id: 'department',
        accessor: "jobs"
    },
    {
        Header: 'Servicios',
        id: 'services',
        accessor: "services?.length"
    },
    {
        Header: 'Edición',
        id: 'edit'
    },
]

const Users = (props) => {

    let users = props.usersAccount;
    let account = props.account;
    let usersAccountLoading = props.usersAccountLoading;

    const { theme } = props;

    const {
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({ columns, data: users }, useSortBy, usePagination);

    const loadUsersByAccountAsync = props.loadUsersByAccountAsync;

    useEffect(() => {
        if (account !== null)
            loadUsersByAccountAsync();
    }, [account, loadUsersByAccountAsync]);

    return (
        <Fragment>
            <Modal
                size={"lg"}
                show={props.showUsersListModal}
                onHide={() => props.setShowUsersListModal(false)}
                className={`rounded-24 surface-bg-surface border-fill-medium ${theme}`}
                backdropClassName={`${theme}`}
                >
                <Modal.Header closeButton={false}>
                    <div className="row-between align-items-center h-100 w-100 ps-3">
                        <div className="row-start align-items-center h-100 gap-3">
                          <h2 className={`heading-h5-bold content-default-primary ${theme}`}>{props.language.users_title_main_header}</h2>
                          <button className={`row-center align-items-center rounded-pill surface-bg-on-surface-alpha border-fill-medium ${theme}`} style={{ width: 32, height: 32 }} onClick={() => { props.setShowCreateUserModal(1); props.setUserAccessEdit(null); }}>
                            <i className={`fa-regular fa-plus txt-10 content-default-primary ${theme}`}></i>
                          </button>
                        </div>
                        <button  onClick={() => props.setShowUsersListModal(false)} className={`column-center align-items-center rounded-30 border-fill-medium surface-bg-on-surface-alpha ${theme}`} style={{width:'34px', height: '34px'}}>
                          <i className={`fa-light fa-xmark txt-10 content-default-primary ${theme}`}></i>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {usersAccountLoading ?
                        <div className="column-center align-items-center w-100 min-height-300 px-3">
                            <Loader inverse center size="md" content={props.language.loader_text_content} />
                        </div> :
                        <Table
                            columns={[
                                // {
                                //     Header: "",
                                //     id: 'id',
                                //     accessor: "idUser"
                                // },
                                {
                                    Header: `${props.language.users_table_header_name}`,
                                    id: 'name',
                                    accessor: "name"
                                },
                                {
                                    Header: `${props.language.users_table_header_lastname}`,
                                    id: 'lastname',
                                    accessor: "lastname"
                                },
                                {
                                    Header: `${props.language.users_table_header_phone}`,
                                    id: 'phone',
                                    accessor: "phone"
                                },
                                {
                                    Header: `${props.language.users_table_header_email}`,
                                    id: 'email',
                                    accessor: "email"
                                },
                                // {
                                //     Header: `${props.language.users_table_header_job_area}`,
                                //     id: 'department',
                                //     accessor: "jobs"
                                // },
                                // {
                                //     Header: `${props.language.users_table_header_services}`,
                                //     id: 'services',
                                //     accessor: "services?.length"
                                // },
                                {
                                    Header: `${props.language.users_table_header_edit}`,
                                    id: 'edit'
                                },
                            ]}
                            page={page}
                            data={users}
                            deleteAction={value => props.deleteUserAsync(value.id)}
                            actions={value => {

                                let user = users.find(u => u.idUser === value.id);

                                user.roleIds = user.jobs.map(s => s.idJob);
                                user.servicesIds = user.services.map(s => s.idService);

                                props.setUserAccessEdit(user);

                            }} />
                    }
                </Modal.Body>
                <Modal.Footer className="max-height-72">
                    <div className={`py-3 mx-3 border-fill-medium-top ${theme}`}>
                        <div className='row-start align-items-center'>
                            <button className={`column-center align-items-center rounded-30 surface-bg-on-surface ${theme}`} style={{width:'34px', height:'34px'}} onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <i className={`fa-light fa-circle-chevron-left txt-12 pe-pointer content-default-tertiary ${theme}`} />
                            </button>
                            <p className={`label-small-regular content-default-primary mx-3 ${theme}`}>{`${props.language.pagination} ${pageIndex + 1} ${props.language.pagination_of} ${pageOptions.length}`}</p>
                            <button className={`column-center align-items-center rounded-30 surface-bg-on-surface ${theme}`} style={{width:'34px', height:'34px'}} onClick={() => nextPage()} disabled={!canNextPage}>
                                <i className={`fa-light fa-circle-chevron-right txt-12 pe-pointer content-default-tertiary ${theme}`} />
                            </button>
                            {/* <p className='txt-phrase txt-regular txt-white txt-8 mx-3'>{props.language.users_pagination_go_to_page}</p> */}
                            {/* <input
                                type="number"
                                min="1"
                                max={pageOptions.length}
                                defaultValue={pageIndex + 1}
                                className='bg-gray-450 txt-phrase txt-regular txt-white txt-8 text-center py-2 border-1 border-color-gray-800 rounded-8'
                                style={{ border: 'none', boxShadow: 'none' }}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                            /> */}
                            {/* <select
                                value={pageSize}
                                className={`ms-3 p-2 rounded-30 surface-bg-on-surface content-default-primary label-samll-regular ${theme}`}
                                style={{ outline: 'none' }}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}>
                                {[5, 10, 15, 20].map((pageSize) => (
                                    <option key={pageSize} value={pageSize} className='txt-phrase txt-medium txt-white txt-8'>
                                        {props.language.users_pagination_show_page} {pageSize}
                                    </option>
                                ))}
                            </select> */}
                            <div className='ms-2' style={{width: '8rem'}}>
                              <MultipleSelect
                                options={[5, 10, 15, 20].map(size => ({ value: size, label: `${props.language.users_pagination_show_page} ${size}` }))}
                                selectedData={[pageSize]} 
                                singleSelect={true} 
                                value={`${props.language.users_pagination_show_page} ${pageSize}`}
                                onChange={(data) => setPageSize(data[0])} 
                                width={'8rem'}
                              />
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    account: state.accounts.account,
    usersAccount: state.users.usersAccount,
    usersAccountLoading: state.users.usersAccountLoading,
    showUsersListModal: state.users.showUsersListModal,
    language: state.shared.language,
    theme: state.theme.theme,
})

const mapDispatchToProps = dispatch => ({
    setShowCreateUserModal: (payload) => dispatch(usersActions.setShowCreateUserModal(payload)),
    setShowUsersListModal: (payload) => dispatch(usersActions.setShowUsersListModal(payload)),
    setUserAccessEdit: (payload) => dispatch(usersActions.setUserAccessEdit(payload)),
    loadUsersByAccountAsync: () => dispatch(usersActions.loadUsersByAccountAsync()),
    deleteUserAsync: (payload) => dispatch(usersActions.deleteUserAsync(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)