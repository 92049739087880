import React, { useEffect, useState } from 'react'

import { connect } from "react-redux";

import { Calendar as Datepicker } from 'react-calendar';
import { Transition } from 'react-transition-group';
import { calendarIntervals, setCalendarDates } from '../../../services/CalendarHandler';
import { subMonths } from 'date-fns';
import { track } from '@amplitude/analytics-browser';

const CalendarInvoices = (props) => {

    let show = props.show;
    let interval = props.interval;
    let start = props.start;
    let end = props.end;
    let cleanDate = props.cleanDate;
    let theme = props.theme;
    
    const setDates = props.setDates;
    const clearDate = props.clear;
    const setShowCalendar = props.setShowCalendar;

    const [selectedDate, setSelectedDate] = useState(!cleanDate ? (interval === 1 ? [start, end] : start) : null);

    useEffect(() => {
        if(!show && cleanDate){
            setSelectedDate(null);
        }
    }, [show, cleanDate])

    return (
        <Transition
            in={show}
            timeout={50}>
            {state => (
                <div className={`position-absolute column-start align-items-center top-100 zindex-6 trans-fade trans-fade-${state}`} style={{ width: 300 }}>
                    <div className={`calendar-arrow surface-bg-surface ${theme}`}></div>
                    <div className={`mt-3 surface-bg-surface rounded-8 ${theme}`}>
                        <Datepicker
                            next2Label={null}
                            prev2Label={null}
                            value={selectedDate}
                            minDetail={calendarIntervals[interval]}
                            maxDetail={calendarIntervals[interval]}
                            maxDate={subMonths(new Date(), 1)}
                            className={`calendar-invoices surface-bg-surface txt-phrase txt-8 p-2 rounded-8 mt-0 pb-0 ${theme}`}
                            nextLabel={<div className={`min-width-26 min-height-26 position-relative column-center align-items-center rounded-circle surface-bg-on-surface ${theme}`}><i className={`position-absolute fa-regular fa-chevron-circle-right txt-8 content-default-tertiary pe-pointer ${theme}`}></i></div>}
                            prevLabel={<div className={`min-width-26 min-height-26 position-relative column-center align-items-center rounded-circle surface-bg-on-surface ${theme}`}><i className={`position-absolute fa-regular fa-chevron-circle-left txt-8 content-default-tertiary pe-pointer ${theme}`}></i></div>}
                            onChange={(value) => {setSelectedDate(value)}} 
                        />
                        <div className={`row-between align-items-center mx-3 pb-3 pt-2 border-fill-medium-top mt-3 ${theme}`}>
                            <p className={`label-small-regular content-color-green pe-pointer ${theme}`} onClick={clearDate}>{props.language.invoices_watch_all_months}</p>
                            <button 
                                className={`row-center gap-2 button-fill-brand pe-pointer rounded-pill align-items-center py-2 px-3 ${theme}`}
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowCalendar(false);
                                    if(selectedDate){
                                        setDates(setCalendarDates(selectedDate, interval))
                                        track('Billing month selected')
                                    }
                                }}
                            >
                                <p className={`label-small-regular button-content-equal pe-pointer ${theme}`}>{props.language.invoices_calendar_done}</p>
                            </button>
                        </div>
                    </div>
                </div>)}
        </Transition>
    )
}

const mapStateToProps = (state, ownProps) => ({
    theme: state.theme.theme,
    ...ownProps
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarInvoices)