import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import * as servicesActions from "../../../store/actions/Services";
import * as invoicesActions from "../../../store/actions/Invoices";

import { Transition } from 'react-transition-group';
import { track } from '@amplitude/analytics-browser';

const AccountDrawer = (props) => {

    const _totalAccounts = props.totalAccounts;
    const _totalServices = props.totalServices;
    const activeServicesEnergy = props.activeServicesEnergy;
    const openAccountDrawer = props.openAccountDrawer;
    const accountPickerRef = props.accountPickerRef;
    const theme = props.theme;
    const language = props.language;
    const fromEnergyAccount = props.fromEnergyAccount;

    const handleClose = props.handleClose;
    const setActiveServicesInvoices = props.setActiveServicesInvoices;
    const setCurrentPage = props.setCurrentPage;
    const setEmptyPaginationResult = props.setEmptyPaginationResult;

    const [energyAccounts, setEnergyAccounts] = useState([]);
    const [totalAccounts, setTotalAccounts] = useState([]);
    const [search, setSearch] = useState('');

    let allAccountsSelected = totalAccounts.every(a => a.isSelected);

    const toggleSelectedAccount = (id_account) => {
        setTotalAccounts(totalAccounts.map(currentAccount => currentAccount.idAccount !== id_account ? currentAccount : ({ ...currentAccount, isSelected: !currentAccount.isSelected })))
        track('Billing account selected')
    }

    const onSaveChanges = () => {
        const deselectedAccounts = totalAccounts
            .filter(currentAccount => !currentAccount.isSelected)
            .map(currentAccount => currentAccount.idAccount);
        const energyAccountsIds = energyAccounts
            .map(currentAccount => currentAccount.idAccount)
        const newActiveServicesInvoices = [..._totalServices];
        setCurrentPage(0)
        setEmptyPaginationResult(false)
        setActiveServicesInvoices(
            newActiveServicesInvoices.filter(currentService =>
                !deselectedAccounts.some(currentIdAccount => currentService.idAccount === currentIdAccount)
                &&
                energyAccountsIds.some(currentIdAccount => currentService.idAccount === currentIdAccount)
            )
        )

        handleClose()
    }

    const toggleSelectAll = (allSelected) => {
        !allAccountsSelected && track('Billing all account selected')
        setTotalAccounts(prevState => prevState.map(currentAccount => ({ ...currentAccount, isSelected: !allSelected })))
    }

    const normalizeText = (text) => {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }

    useEffect(() => {
        if (!_totalAccounts || _totalAccounts.length === 0) {
            setTotalAccounts([])
            return;
        }
        const activeServicesEnergyAccountIds = activeServicesEnergy.map(s => s.account?.idAccount);
        const energyAccounts = _totalAccounts.filter(currentAccount => currentAccount.typeservices.includes('energy'))
        setEnergyAccounts(energyAccounts)
        const normalizedAccounts = energyAccounts.map(currentAccount => ({
            ...currentAccount,
            isSelected: (fromEnergyAccount && activeServicesEnergyAccountIds.length > 0) ? activeServicesEnergyAccountIds.includes(currentAccount.idAccount) : true,
            visible: true
        }))
        setTotalAccounts(normalizedAccounts)
        // eslint-disable-next-line
    }, [_totalAccounts, fromEnergyAccount])

    useEffect(() => {
        if (!search || search.length === 0) {
            setTotalAccounts(prevState => prevState.map(currentAccount => ({ ...currentAccount, visible: true })))
        } else {
            setTotalAccounts(prevState => prevState.map(currentAccount => ({ 
                ...currentAccount, 
                visible: normalizeText(currentAccount.name)
                    .toLowerCase()
                        .includes(normalizeText(search.toLowerCase())) 
            })))
        }
    }, [search])

    return (
        <Transition
            in={openAccountDrawer}
            timeout={50}>
            {state => (
                <div className={`position-fixed column-start align-items-center top-0 zindex-6 trans-fade trans-fade-${state}`} style={{ width: 320, marginTop: 175 }}>
                    <div className={`invoice-state-arrow surface-bg-surface ${theme}`}></div>
                    <div ref={accountPickerRef} className={`surface-bg-surface rounded ${theme}`} style={{ boxShadow: '0 1px 3px #0000004D' }}>
                        <div className={`w-100 pt-3 pb-3 px-3 sticky-top`}>
                            <div className={`row-start align-items-center border-fill-regular px-3 py-2 rounded-8 ${theme}`}>
                                <i className={`fal fa-search txt-10 me-2 content-default-primary ${theme}`} />
                                <input
                                    type="text"
                                    className={`label-medium-medium content-default-secondary surface-bg-surface border-0 w-100 ${theme}`}
                                    // placeholder={language.energy_graph_type_picker_search}
                                    placeholder="Buscar cuenta"
                                    value={search}
                                    onChange={({ target }) => setSearch(target.value)}
                                />
                            </div>
                        </div>
                        <div className={`w-100 px-3`}>
                            <div className={`w-100 border-fill-medium-bottom ${theme}`} />
                        </div>
                        <div className="max-height-300 column-start gap-3 ps-3 pe-2 py-2 overflow-auto overflow-auto">
                            {
                                totalAccounts.filter(a => a.visible).map(currentAccount => (
                                    <div
                                        key={`[invoice-drawer-account]${currentAccount.idAccount}`}
                                        className={`w-100 row-between align-items-center px-3 py-2 rounded-10 pe-pointer ${currentAccount.isSelected ? 'surface-bg-on-surface' : 'hover-surface-bg-on-surface'} ${theme}`}
                                        onClick={() => toggleSelectedAccount(currentAccount.idAccount)}
                                    >
                                        <div className="column-start">
                                            <div className={`pb-1 pe-pointer max-width-200 text-nowrap label-small-medium content-default-primary overflow-hidden ${theme}`} style={{ textOverflow: 'ellipsis' }}>{currentAccount.name}</div>
                                            <div className={`label-small-medium content-default-secondary pe-pointer ${theme}`}>{currentAccount.services.length} {props.language.invoices_accounts_services_selected_unit}</div>
                                        </div>
                                        <div>
                                            <i
                                                className={`fal fa-circle-check txt-10 pe-pointer ${currentAccount.isSelected ? 'content-color-green' : 'content-default-secondary'} ${theme}`}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className={`w-100 px-3`}>
                            <div className={`w-100 border-fill-medium-bottom ${theme}`} />
                        </div>
                        <div className={`w-100 row-between align-items-center p-3 ${theme}`}>
                            <p
                                className={`label-small-regular content-color-green pe-pointer ${theme}`}
                                onClick={() => toggleSelectAll(allAccountsSelected)}
                            >
                                {!allAccountsSelected ? props.language.invoices_accounts_select_all : props.language.invoices_accounts_deselect_all }
                            </p>
                            <button
                                className={`row-center gap-2 button-fill-brand pe-pointer rounded-pill align-items-center py-2 px-3 ${theme}`}
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onSaveChanges()
                                }}
                            >
                                <p className={`label-small-regular button-content-equal pe-pointer ${theme}`}>{props.language.invoices_accounts_done}</p>
                            </button>
                        </div>
                    </div>
                </div>)}
        </Transition>
    )
}

const mapStateToProps = (state, props) => ({
    totalAccounts: state.accounts.totalAccounts,
    totalServices: state.services.totalServices,
    activeServicesEnergy: state.services.activeServicesEnergy,
    theme: state.theme.theme,
    language: state.shared.language,
    ...props
})

const mapDispatchToProps = (dispatch) => ({
    setActiveServicesInvoices: (payload) =>
        dispatch(servicesActions.setActiveServicesInvoices(payload)),
    setCurrentPage: (payload) =>
        dispatch(invoicesActions.setCurrentPage(payload)),
    setEmptyPaginationResult: (payload) =>
        dispatch(invoicesActions.setEmptyPaginationResult(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountDrawer);